import React , {useState} from 'react'

function CostAuditors({index,id, costAuditors, onCostAuditorUpdate, onCostAuditorSubmit,onCostAuditorsRemove}) {
  const [sname, setCostAuditor] = useState(costAuditors ? costAuditors : null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const newShareholder= { id, sname};
    if (costAuditors) {
      onCostAuditorUpdate(id, newShareholder);
    } else {
      onCostAuditorSubmit(newShareholder);
    }
    setCostAuditor("");
  };
  const handleRemove = () => {
    onCostAuditorsRemove(index); // Call the parent component's remove function
  };
  return (
    <div>
    <form className="ca-form single-box">
      <div className="input-container">
          <label>Cost Auditor Name</label>
          <input type="text" name="name" className='form-input' value={sname} onChange={(event) => setCostAuditor(event.target.value)}/>
      </div>
      <button className='remove-btn' onClick={handleRemove}>X</button>
    </form>
    
  </div>
  )
}

export default CostAuditors