import Address from "./supportingComponents/Address";
import Directors from "./supportingComponents/Directors";
import Shareholders from "./supportingComponents/Shareholders";
import StatutoryAuditors from "./supportingComponents/StatutoryAuditors";
import CostAuditors from "./supportingComponents/CostAuditors";
import SecretarialAuditors from "./supportingComponents/SecretarialAuditors";
import RelatedParty from "./supportingComponents/RelatedParty";
import CompanyName from "./supportingComponents/CompanyName";
import { saveCompany,getCompany } from "../../data/companies";
import React, { useState,useEffect, useContext } from "react";
import { useParams , useNavigate } from 'react-router-dom';
import { post } from "../../context/rest";
import SessionExpiredPopup from "../SessionExpiredPopup";
import { UploadOutlined, ExclamationCircleFilled , CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from 'antd';



const CompanyDetails = ({location}) => {

  // const { userData } = location.state;

  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };

  const { id } = useParams();
  const { confirm } = Modal;
  // add address component
  const [addresses, setAddresses] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [shareHolders, setShareholders] = useState([]);
  const [statutoryAuditors, setStatutoryAuditors] = useState([]);
  const [costAuditors, setCostAuditors] = useState([]);
  const [secretarialAuditors, setSecretarialAuditors] = useState([]);
  const [relatedParty, setRelatedParty] = useState([]);
  const [companyName, setCompanyName] = useState([]);



function setAuditors(data)
{
  var statutory = []
  var cost = []
  var secretarial = []
  data.forEach(element => {
    switch (element.type) {
      case "STATUTORY":
        statutory.push(element.name)
        break;
      case "COST":
        cost.push(element.name)
        break;
      case "SECRETARIAL":
        secretarial.push(element.name)
        break;
    
      default:
        break;
    }
  });
  setCostAuditors(cost);
  setSecretarialAuditors(secretarial);
  setStatutoryAuditors(statutory);
}

  const handleAddressUpdate = (updatedAddress) => {
    const updatedAddresses = addresses.map((address) =>
      address.id === updatedAddress.id ? updatedAddress : address
    );
    setAddresses(updatedAddresses);
  };

  const handleDirectorUpdate = (updatedDirector) => {
    const updatedDirectors = directors.map((director) =>
    director.id === updatedDirector.id ? updatedDirector : director
    );
    setDirectors(updatedDirectors);
  };
  const handleStatutoryAuditorUpdate = (updatedStatutoryAuditor) => {
    const updatedStatutoryAuditors = statutoryAuditors.map((statutoryAuditor) =>
    statutoryAuditor.id === updatedStatutoryAuditor.id ? updatedStatutoryAuditor : statutoryAuditor
    );
    setStatutoryAuditors(updatedStatutoryAuditors);
  };
  const handleCostAuditorUpdate = (updatedCostAuditor) => {
    const updatedCostAuditors = costAuditors.map((costAuditor) =>
    costAuditor.id === updatedCostAuditor.id ? updatedCostAuditor : costAuditor
    );
    setCostAuditors(updatedCostAuditors);
  };
  const handleSecretarialAuditorUpdate = (updatedSecretarialAuditor) => {
    const updatedSecretarialAuditors = costAuditors.map((secretarialAuditor) =>
    secretarialAuditor.id === updatedSecretarialAuditor.id ? updatedSecretarialAuditors : secretarialAuditor
    );
    setSecretarialAuditors(updatedSecretarialAuditors);
  };
  const handleShareholderUpdate = (updatedShareholder) => {
    const updatedShareholders = shareHolders.map((shareHolder) =>
    shareHolder.id === updatedShareholder.id ? updatedShareholder : shareHolder
    );
    setShareholders(updatedShareholders);
  };
  const handleRelatedPartyUpdate = (updatedRelatedParty) => {
    const updatedRelatedParties = updatedRelatedParty.map((relatedParty) =>
    relatedParty.id === updatedRelatedParty.id ? updatedRelatedParty : relatedParty
    );
    setRelatedParty(updatedRelatedParties);
  };
  const handleCompanyNameUpdate = (updatedCompanyName) => {
    const updatedCompanyNames = updatedCompanyName.map((company) =>
    company.id === updatedCompanyName.id ? updatedCompanyName : company
    );
    setCompanyName(updatedCompanyNames);
  };


  const handleAddAddress = () => {
    setAddresses((addresses) => [
      ...addresses,
      <Address key={addresses.length} />,
    ]);
  };

  const handleAddDirectors = () => {
    setDirectors((directors) => [
      ...directors,
      <Directors key={directors.length} />,
    ]);
  };

  const handleAddShareholders = () => {
    if(shareHolders.length >= 5){
      var msg =  document.getElementById('shareholder-msg');
      var msgDiv = document.getElementById('msg-div');
     msg.innerText = "You can add 5 Shareholders only!";
     msg.classList.add('shareholder-msg');
      msgDiv.classList.add('msg-div');
    }
    else{
      setShareholders((shareHolders) => [
        ...shareHolders,
        <Shareholders key={shareHolders.length} />,
      ]);
    }
   
  };
  const handleAddStatutoryAuditors = () => {
    setStatutoryAuditors((statutoryAuditors) => [
      ...statutoryAuditors,
      <StatutoryAuditors key={statutoryAuditors.length} />,
    ]);
  };
  const handleAddCostAuditors = () => {
    setCostAuditors((costAuditors) => [
      ...costAuditors,
      <CostAuditors key={costAuditors.length} />,
    ]);
  };
  const handleAddSecretarialAuditors = () => {
    setSecretarialAuditors((secretarialAuditors) => [
      ...secretarialAuditors,
      <SecretarialAuditors key={costAuditors.length} />,
    ]);
  };
  const handleAddRelatedParty = () => {
    setRelatedParty((relatedParty) => [
      ...relatedParty,
      <RelatedParty key={relatedParty.length} />,
    ]);
  };
  const handleAddCompanyName = () => {
    setCompanyName((companyName) => [
      ...companyName,
      <CompanyName key={companyName.length} />,
    ]);
  };
  console.log(directors);

  //address remove
  const handleAddressRemove = (indexToRemove) => {
    const updatedAddresses = addresses.filter((_, index) => index !== indexToRemove);
    setAddresses(updatedAddresses);
  };

  const handleShareholderRemove = (indexToRemove) => {
    const updatedShareholders = shareHolders.filter((_, index) => index !== indexToRemove);
    setShareholders(updatedShareholders);
  };

  const handleDirectorRemove = (indexToRemove) => {
    const updatedDirectors = directors.filter((_, index) => index !== indexToRemove);
    setDirectors(updatedDirectors);
  };
  const handleStatutoryAuditorRemove = (indexToRemove) => {
    const updatedstAuditor = statutoryAuditors.filter((_, index) => index !== indexToRemove);
    setStatutoryAuditors(updatedstAuditor);
  };
  const handleCostAuditorRemove = (indexToRemove) => {
    const updatedCostAuditor = costAuditors.filter((_, index) => index !== indexToRemove);
    setCostAuditors(updatedCostAuditor);
  };
  const handleSecretarialAuditorRemove = (indexToRemove) => {
    const updatedSecAuditor = secretarialAuditors.filter((_, index) => index !== indexToRemove);
    setSecretarialAuditors(updatedSecAuditor);
  };
  const handleRelatedPartyRemove = (indexToRemove) => {
    const updatedRP = relatedParty.filter((_, index) => index !== indexToRemove);
    setRelatedParty(updatedRP);
  };
  



  async function handleFormSubmit(e) {
    e.preventDefault();
    const aValues = Array.from(e.target.querySelectorAll(".a-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const dValues = Array.from(e.target.querySelectorAll(".d-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const sValues = Array.from(e.target.querySelectorAll(".s-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const saValues = Array.from(e.target.querySelectorAll(".sa-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const caValues = Array.from(e.target.querySelectorAll(".ca-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const seValues = Array.from(e.target.querySelectorAll(".se-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const rpValues = Array.from(e.target.querySelectorAll(".rp-form")).map(
      (form) => Object.fromEntries(new FormData(form).entries())
    );

    const companyNameValues = Array.from(
      e.target.querySelectorAll(".c-form")
    ).map((form) => Object.fromEntries(new FormData(form).entries()));

    var auditorsArray = [];
    saValues.forEach((element) => {
      var newAuditor = {
        name: element.name,
        type: "STATUTORY",
      };
      auditorsArray.push(newAuditor);
    });
    caValues.forEach((element) => {
      var newAuditor = {
        name: element.name,
        type: "COST",
      };
      auditorsArray.push(newAuditor);
    });
    seValues.forEach((element) => {
      var newAuditor = {
        name: element.name,
        type: "SECRETARIAL",
      };
      auditorsArray.push(newAuditor);
    });

    var cname;
    companyNameValues.forEach((element) => {
      cname = element.name;
    });
    // var firm = firm.get()
    var firmId = sessionStorage.getItem("firmId")
    var formData = {
      name: cname,
      addresses: aValues,
      directors: dValues,
      auditors: auditorsArray,
      shareHolders: sValues,
      relatedParties: rpValues,
      firm: { id: firmId },
    };

    return confirm({
      title: "Do you Want to save these details?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        var response = post(`clientFirm/user/add`,formData);
		console.log(response);
    if(response.unauthorized){
      setShowPopup(true)
    }
        navigate(-1);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  
    
  }


  return (
    <div className="list-container">
      <div className="header-container">
        <span className="heading">
          <h3>Register Company</h3>
        </span>
      </div>
      <div className="form-container">
        <form onSubmit={handleFormSubmit}>
          <CompanyName onChange={handleAddCompanyName} companyName={companyName} onCompanyUpdate={handleCompanyNameUpdate}/>
          <div className="multiple-input-container">
            <div className="form-header">
              <label>Address</label>
              <button
                title="Add Address"
                type="button"
                onClick={handleAddAddress}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{addresses.map((item,index) => (
          <Address
              index ={index}
            key={item.id}
            addresses={item}
            onAddressUpdate={handleAddressUpdate}
            onAddressRemove={handleAddressRemove}
          />
        ))}</div>
          </div>

          <div className="multiple-input-container">
            <div className="form-header">
              <label>Director</label>
              <button
                title="Add Director"
                type="button"
                onClick={handleAddDirectors}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{directors.map((item,index) => (
          <Directors
              index ={index}
            key={item.id}
            directors={item}
            onDirectorUpdate={handleDirectorUpdate}
            onDirectorRemove={handleDirectorRemove}
          />
        ))}</div>
           
          </div>

          <div className="multiple-input-container">
            <div className="form-header">
              <label>Shareholders</label>
              <button
                title="Add Shareholder"
                type="button"
                onClick={handleAddShareholders}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            
            <div className="inner-form">
            <div id="msg-div"><label htmlFor="" id="shareholder-msg"></label></div>
              {shareHolders.map((item,index) => (
          <Shareholders
              index ={index}
            key={item.id}
            shareHolders={item}
            onShareholderUpdate={handleShareholderUpdate}
            onShareholderRemove={handleShareholderRemove}
          />
        ))}</div>
          </div>

          <div className="multiple-input-container">
            <div className="form-header">
              <label>Statutory Auditor</label>
              <button
                title="Add Statutory Auditor"
                type="button"
                onClick={handleAddStatutoryAuditors}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{statutoryAuditors.map((item,index) => (
          <StatutoryAuditors
              index ={index}
            key={item.id}
            statutoryAuditors={item}
            onStatutoryAuditorUpdate={handleStatutoryAuditorUpdate}
            onStatutoryAuditorsRemove={handleStatutoryAuditorRemove}
          />
        ))}</div>
          </div>

          <div className="multiple-input-container">
            <div className="form-header">
              <label>Cost Auditor</label>
              <button
                title="Add Cost Auditor"
                type="button"
                onClick={handleAddCostAuditors}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{costAuditors.map((item,index) => (
          <CostAuditors
              index ={index}
            key={item.id}
            costAuditors={item}
            onCostAuditorUpdate={handleCostAuditorUpdate}
            onCostAuditorsRemove={handleCostAuditorRemove}
          />
        ))}</div>
          </div>

          <div className="multiple-input-container">
            <div className="form-header">
              <label>Secretarial Auditor</label>
              <button
                title="Add Secretarial Auditor"
                type="button"
                onClick={handleAddSecretarialAuditors}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{secretarialAuditors.map((item,index) => (
          <SecretarialAuditors
              index ={index}
            key={item.id}
            secretarialAuditors={item}
            onSecretarialAuditorUpdate={handleSecretarialAuditorUpdate}
            onSecretarialAuditorsRemove={handleSecretarialAuditorRemove}
          />
        ))}</div>
          </div>
          <div className="multiple-input-container">
            <div className="form-header">
              <label>Related Party</label>
              <button
                title="Add Related Party"
                type="button"
                onClick={handleAddRelatedParty}
              >
                <i class="fa-solid fa-plus"></i>Add
              </button>
            </div>
            <div className="inner-form">{relatedParty.map((item,index) => (
          <RelatedParty
              index ={index}
            key={item.id}
            relatedParty={item}
            onRelatedPartyUpdate={handleRelatedPartyUpdate}
            onRelatedPartyRemove={handleRelatedPartyRemove}
          />
        ))}</div>
          </div>
          <div>
            <button type="submit" className="company-submit">
              Submit
            </button>
          </div>
          {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>
          }
        </form>
      </div>
    </div>
  );
}

export default CompanyDetails;
