import React, { useState, useEffect } from 'react'
import { useParams , useNavigate } from 'react-router-dom';
import { post , get } from '../../context/rest';
import './updatePassword.css';
import  Header  from "../header/Header";
import queryString from 'query-string';
// import { Base64 } from "js-base64";

function UpdatePassword() {
  const [password,setPassword] = useState();
  const [confirmPassword,setConfirmPassword] = useState();
  const [userEmail,setUserEmail] = useState();
  const [epassword,setExistingPassword] = useState();
  const [fromMail,setFromMail] = useState();
  const navigate = useNavigate()
  // const { tempKey, fromMail } = useParams();
  
 

  useEffect(() => {
    function fetchData() {
       const queryParameters = new URLSearchParams(window.location.search)
       const decodedPwd = atob(queryParameters.get("tempKey"));
       const decodedEmail = atob(queryParameters.get("userEmail"));
     
       console.log("decoded password",decodedPwd);
       console.log("decoded email",decodedEmail);
      setExistingPassword(decodedPwd);
      setFromMail(queryParameters.get("fromMail"));
      setUserEmail(decodedEmail);

    }
    
    fetchData();
  }, []);

  function checkPassword(pwd){
    if (password != pwd) {
      document.getElementById('incr-pwd').classList.add('show-msg');
      document.getElementById('pwd-chng-btn').classList.add("gray")
    }
    else{
        document.getElementById('incr-pwd').classList.remove('show-msg');
        document.getElementById('pwd-chng-btn').disabled = false;
        document.getElementById('pwd-chng-btn').classList.remove("gray")
    }
  }

  async function updatePassword() {

      var updatePassword = `appUser/userAction/updatePassword?userEmail=${userEmail}&existingPassword=${epassword}&newPassword=${password}`;
  
    var res = await post(updatePassword);
    console.log(res);

  //sessionStorage.setItem("userId",formData.userName);
    navigate('/login')
  }
  
  return (
    <div>
      <Header />
      <div className="pwd-form-container">
        <h3>Update Password</h3>
        <div className="msg">
          <p id="incr-pwd" className='err-msg'>Passwords are not same!</p>
          <p className='success-msg'></p>
        </div>
        { fromMail == null?  <div className="input-container">
          <label htmlFor="">Existing Password</label>
          <input type="password" name="password" id="pwd" onChange={(event) => setExistingPassword(event.target.value)} className='form-input'/>
        </div>: ""}
       
        <div className="input-container">
          <label htmlFor="">New Password</label>
          <input type="password" name="password" id="pwd" onChange={(event) => setPassword(event.target.value)} className='form-input'/>
        </div>
        <div className="input-container">
          <label htmlFor="">Confirm Password</label>
          <input type="password" name="confirmPassword" id="pwd" onChange={(event) => checkPassword(event.target.value)} className='form-input'/>
        </div>
        <button id='pwd-chng-btn' type="button" onClick={updatePassword} className='form-input sub-button gray'>Change Password</button>
      </div>
    </div>
  )
}

export default UpdatePassword