


import {
  AutoComplete,
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Table,
  TimePicker,
  message,
  Divider
} from "antd";
import React, { useState , useEffect, useContext,useReducer  } from 'react'
import { useLocation } from 'react-router-dom';
import {INTEGER_WIDGET, getWidget} from './supportingComponents/widgets'
import Company from './supportingComponents/Company';
import { getCompanies,getAllCompanies } from '../../data/companies';
import { getTemplates } from '../../data/templates';
import { previewDocument, saveDocument } from "../../data/documents";
import Template from './supportingComponents/Template';
import LoadParameters from './supportingComponents/LoadParameters';
import  Preview from './Preview'
import Popup from './supportingComponents/popup';
import SelectedAgenda from "./supportingComponents/SelectedAgenda";
import SessionExpiredPopup from "../SessionExpiredPopup";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, uploadWithForm } from "../../context/rest";
import './document.css'

const formDataReducer = (state, action) => {
  switch (action.type) {
    case 'REMOVE_AGENDA':
      console.log('Removing agenda at index:', action.index);
      return state.filter((_, i) => i !== action.index);
    case 'ADD_AGENDA':
      return [...state, action.data];
    default:
      return state;
  }
};



const { TextArea } = Input;
const DocumentDetails = () => {

  const navigate = useNavigate();
  const { name } = useParams();
  const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };


  const [companies,setCompanies] = useState([]);
  const [templates,setTemplates] = useState([]);
  const [templateFormData, setTemplateFormData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [mainTemplates,setMainTemplates] = useState([])
  const [subTemplatesList,setSubTemplates] = useState([])
  const [template,setTemplate] = useState()
  const [parameters,setParameters] = useState([])
  const [subTemplateParams,setSubParameters] = useState([])
  const [companyName,setCompanyName] = useState("")
  const [docs, setDocs] = useState([]);
  const [subTemplateValue, setSubTemplateValue] = useState([]);
  const [subTemplate, setSubTemplate] = useState([]);
  const [previousValue, setPreviousValue] = useState({});
  const [index, setIndex] = useState({});
  const [financialYear, setFinancialYear] = useState("");

  //  const [formData, dispatchFormData] = useReducer(formDataReducer, []);

  var id = 1000;

  // custom start
const [formType, setFormType] = useState(null);

const handleSave =  (data) => {
    setFormData([ ...formData, data]);
    // handleAddAgenda(data);
document.getElementById('success-message').innerHTML = "Data saved Successfully!";
document.getElementById('success-message').classList.add("msg-background");
};

// const handleAddAgenda = (data) => {
//   dispatchFormData({ type: 'ADD_AGENDA', data });
// };

const generateForm = () => {
function handleSelectChange(val) {
subTemplatesList.forEach(element => {
  if (element.name == val) {
    setSubTemplate(element)
    setSubParameters(element.parameters)
  }
})
}
const handleFormSubmit = (e) => {
  e.preventDefault()
  handleSave(subTemplate)
};

return (
  <form onSubmit={(e) => handleFormSubmit(e)} >
    <div className="popup-header">
      <label htmlFor="">Add Sub Template</label>
      <button onClick={handlePopupClose}>X</button>
    </div>
   
    <div className="popup-top-container">
      <div className="input-container selectContainer">
        <label htmlFor="">Select Sub Template</label>
        <Select name="select" id="select" onChange={(value) =>handleSelectChange(value) }
    showSearch
    placeholder="Select Sub Template"
    optionFilterProp="children"
    filterOption={(input, option) =>
       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
      {subTemplatesList.map((option,index) => (
           
              <option key={option.name} value={option.name} id={index}>
                {option.name}
               
              </option>
            ))}
    </Select>
      
          <div className="inner-form load-parameters">{subTemplateParams.map((item,index) => (
       
       <LoadParameters
           index ={index}
           label = {item.parameterName}
           onFormUpdate = {handleSubFormParamUpdate}
           type = {item.type}
           value={item.value}
           company = {companyName}
           subTemplatesList={subTemplatesList}
           isHidden={item.isHidden}
       />
       
))}</div>
      </div>
      
    </div>
    <div className="popup-form-fields" id="subTemplateForm">

    </div>
    <p id="success-message" className="success-message"></p>
    <div className="btn-container">
    <button type="submit" className="btn">Save</button>
    </div>
    
  </form>
);
};

function handleSubFormParamUpdate(field,value){
  subTemplate.parameters[field].value = value;
  setSubTemplateValue(value)
}
const generateCustomForm = () => {

const handleFormSubmit = (e) => {
  e.preventDefault();
  var customTemplate = {
    agenda:null,
    clientFirmId:null,
    documentTypeId:null,
    id:null,
    isSubTemplate:null,
    name:null,
    mainTemplateId:null,
    isDeleted:null,
    subTemplates:[],
    templateDocument:null,
    parameters:[]
  }
  var input1 = document.getElementById('input1').value;
  var custom = document.getElementById('input2').value;
  var parameter1 = {
    id:"",
    parameterName : "name",
    value:input1,
    type:"STRING"
  }
  var parameter2 = {
    id:"",
    parameterName : "Custom Template",
    value:custom,
    type:"STRING"
  }
  customTemplate.parameters.push(parameter1);
  customTemplate.parameters.push(parameter2);
  handleSave(customTemplate);
};

return (
  <div>
     <form onSubmit={(e)=> handleFormSubmit(e)} id="subTemplateForm">
      <div className="popup-header">
        <label>Add Custom Template</label>
      <button onClick={handlePopupClose}>X</button>
      </div>
        
        <div className="input-container">
          <label htmlFor="input1">Subject Matter</label>
          <Input type='text'  id="input1"/>
        </div>
        <div className="input-container">
          <label htmlFor="input2">Resolution Matter</label>
          <TextArea  id="input2" rows="10" cols="20" className="form-input"/>
        </div>
        <p id="success-message" className="success-message"></p>
        <div className="btn-container">
         <button type="submit" className="btn">Save</button>
      </div>
    </form>
  </div>
  
);
}

const handlePopupClose = async () => {
setFormType(null);
document.getElementById('success-message').innerHTML = "";
 setTemplateValues(templateFormData);
   const uri = await previewDocument(companyName.id, template, companyName.name);
   if (uri) {
    setDocs([{ uri, fileType: "docx" }]);
   }
};

function setTemplateValues(data) {
  for (let index = 0; index < template.parameters.length; index++) {
   
      template.parameters[index].value = data[index];
      if (template.parameters[index].type == 'DIRECTOR_TABLE_OF_ATTENDANCE') {
             
              template.parameters[index].value = data[index];
            }
      if (template.parameters[index].type.includes("list") && template.parameters[index].type != 'DIRECTOR_TABLE_OF_ATTENDANCE') {
         
          var arr = [];
          arr.push(data[index]);
          template.parameters[index].value = arr;
    }
    if (template.parameters[index].parameterName == "agenda items") {
      console.log("Form Data t",formData);
      template.parameters[index].value = formData
    }
    if (template.parameters[index].parameterName == "agenda of AGM") {
      
      template.parameters[index].value = formData
    }
    
  }
  
}

// custom end

  useEffect(() => {
    // var Firm = firm.get()
   var firmId = sessionStorage.getItem("firmId");
   var res = [];
    async function fetchData() {
      try {
        if (sessionStorage.getItem('isYearlySubscribed') == "true") {
          console.log("yearly subscribed");
          res = await getAllCompanies(firmId)
        }
        else{
          console.log("trial subscribed");
          res = await getCompanies(firmId);
        }
      
       if (res.unauthorized) {
          setShowPopup(true)
       }
       else{
        setCompanies(res);
       }
       
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);
  const handleFormUpdate = (fieldId, value) => {
    var companyName = value;
    getTemplatesForCompany(companyName)
  };
  const handleTemplateUpdate = (fieldId, value) => {
    var templateName = value;
    var mainTemplate;
    var subTemplatesArray = []
    var arr = []
    if (typeof templateName == "string") {
      mainTemplate = mainTemplates.find((x) => x.name === templateName);
      mainTemplate.subTemplates.forEach(element => {
        if (element.isDeleted != "Y") {
          subTemplatesArray.push(element)
        }
      });
      setTemplate(mainTemplate)
      setSubTemplates(subTemplatesArray)
      setParameters(mainTemplate.parameters)
    }
    
  };

  async function getTemplatesForCompany(templateName){
   
    var nameOrId = templateName;
    var subTemplatesList = []
    var mainTemplates = []
    if (typeof nameOrId == "string") {
        nameOrId = companies.find((x) => x.name === nameOrId)?.id;
      }
      var company ;
      if (typeof nameOrId == "number") {
       company = companies.find((x) => x.id === nameOrId)
       setCompanyName(company);
        var templates = await getTemplates(nameOrId);
        var typeId = 1000;
        var firmId = sessionStorage.getItem('firmId');
        var temp = await get(`documentTemplate/user/getAll?typeId=${typeId}&clientFirmId=${firmId}`)
        if (templates.unauthorized) {
          setShowPopup(true);
        }
   
      templates.forEach(element => {
        if (element.isSubTemplate=="N" && element.isDeleted=="N") {
          if ( !mainTemplates.includes(element)) {
            mainTemplates.push(element)
          }
          
        }
       });
       setMainTemplates(mainTemplates)
  }

  }
  const handleFormParamUpdate = (fieldId, value) => {
    setTemplateFormData({ ...templateFormData, [fieldId]: value }); // Update form data here
	 if (typeof value == "string") {
    if (value.includes("-")) {
      setFinancialYear(value)
    }
   }
  };

  function handleChange(){
    console.log("Final Template:",template);
    var date = new Date();
    saveDocument(
      companyName.id,
      template,
      date.current ? date.current : companyName.name
    );
   }

  //  const handleRemoveAgenda = (index) => {
  //   console.log('Dispatching REMOVE_AGENDA');
  //   dispatchFormData({ type: 'REMOVE_AGENDA', index });
  // };
  
  //  function handleRemoveAgenda(index) {
  //   setFormData(prevFormData => {
  //     return prevFormData.filter((_, i) => i !== index);
  //   });
  //   console.log("f data",formData);
  // }
  async  function handleRemoveAgenda(index) {
      const updatedRows = [...formData];
       updatedRows.splice(index, 1);
       setFormData(updatedRows);
   }
   function handleEditAgenda(option,index) {
    setIndex(index);
    if (option.id != null) {
      setFormType("select");
      setPreviousValue(option)
    }
    else{
      setFormType("textarea")
    }
   }

  return (
    <div id="doc-container">
      <div className="header-container" id="generate-min-heading">
        <span className="heading">
            <h3>Generate Minutes</h3>
          </span>
    </div>
    <div id="outer-container">
    
    <div className='left'>
      <Company options={companies} onFormUpdate={handleFormUpdate} val={name}/>
      <Template options={mainTemplates} onFormUpdate={handleTemplateUpdate}/>
      <div>{parameters.map((item,index) => (
       
                            <LoadParameters
                                index ={index}
                                label = {item.type.includes("TABLE") ? "Attendance Table": item.parameterName}
                                onFormUpdate = {handleFormParamUpdate}
                                type={item.type}
                                value = {item.value}
                                company = {companyName}
                                subTemplatesList={subTemplatesList}
                                isHidden={item.isHidden}
                                financialYear={financialYear}
                            />
                            
        ))}</div>
        <div>
        {parameters.map((item,index) => (
       
          item.type == "LIST_OF_SUB_TEMPLATES" ? 
          <div>
            <div className="agenda-text">
          <label htmlFor="">Agenda Items</label>
          <p>Select agenda items sequentially from options given below.</p>
        </div>
          <div className="popup-btn-container">
          
            <div className="buttons">
            <button onClick={() => setFormType("select")} id="subTemplateButton">Select from Ready Templates </button>
            <button onClick={() => setFormType("textarea")} id="customAgendaButton">Add your own Agenda</button>
            </div>
          </div>
          
         
          {formType === "select" && (
            <div className="popup-overlay">
                <Popup handleSave={handleSave} generateForm={generateForm} />
            </div>
            
          )}
          {formType === "textarea" && (
             <div className="popup-overlay">
             <Popup handleSave={handleSave} generateForm={generateCustomForm}/>
         </div>
           
          )}
        </div> : ""
))}
        </div>
        <div className="agenda-container">
          {formData.length > 0 ? 
               <SelectedAgenda agenda={formData} editAgenda={handleEditAgenda} removeAgenda={handleRemoveAgenda}/> : ""
        }
         
        </div>
        
        <div className="btn-container">
            <button className="btn-sec" class="btn-sec">Back</button>
            <button onClick={handleChange}>Generate</button>
        </div>
    </div>
    <div className="divider"/>
    <div className='right'>
    <Preview docs={docs}></Preview>
    </div>
    {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>
          }
    </div>
   
    </div>
   
  )
}

export default DocumentDetails