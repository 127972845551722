import React from 'react'
import logo from './Logo-minutes-removebg-preview.png';
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';

function Header() {
    
  return (
    <div class="header-new">
    <div className="nav-logo" style={{height:"110px"}}>
        <div className="img-container">
        <Lottie animationData={button} />
        </div>
        <h3 style={{color:"#1390ae"}}>EaseOpt</h3>
      </div>
  {/* <a href="#default" class="logo"><img src={logo} alt="Logo" style={{width:'100px',height:'100px', objectFit:'contain'}} /></a> */}
  <div class="header-right">
            <a href='/home'>Home</a>
            <a href='/home#About'>About Us</a>
            <a href='/userGuide'>User Guide</a>
            <a href='/home#Solution'>Why EaseOpt</a>
            <a href='/home#EaseOpt'>EaseOpt Solutions</a> 
            <a href='/query'>Query</a>
            <a href='/login' className='test' id='login'>Login</a>
  </div>
</div>
  )
}

export default Header