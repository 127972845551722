import React from 'react'

function MessagePopup({onClose,email}) {
    return (
        <div className="popup session-popup" style={{height:"20%"}}>
          <div className="popup-content session-popup-content">
           
          <div className="popup-header">
            <p className='session-popup-header'>Email Sent</p>
            <button onClick={onClose}>X</button>
        </div>
            <p>Verification email sent successfully on <b>{email}</b>. Kindly check email for further process. </p>
           
          </div>
        </div>
      );
}

export default MessagePopup