import React, { useState, useEffect, useContext } from "react";
import RegisteredCompanies from './supportingComponents/RegisteredCompanies'
import { getAllCompanies } from "../../data/companies";
import { useSelector } from 'react-redux';
import SessionExpiredPopup from "../SessionExpiredPopup";
import { Link, useNavigate} from "react-router-dom";
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';

const CompanyList = () => {
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
  const[isDataLoaded,setIsDataLoaded] = useState(true);
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };
    const [companies, setCompanies] = useState([]);
    var typeId=1000;
    var firmId = sessionStorage.getItem("firmId");
  //  var Firm = firm.get()
    useEffect(() => {
        async function fetchData() {
          try {
           var result = await getAllCompanies(firmId);
           console.log("companies",result);
           setIsDataLoaded(false);
           document.getElementById('spin').classList.remove('loader-overlay')
           if (result.unauthorized) {
              setShowPopup(true);
           }
           else{
            var activeCompanies = [];
            result.forEach(element => {
               if (element.isDeleted == 'N') {
                 activeCompanies.push(element)
               }
            });
            setCompanies(activeCompanies);
             console.log(activeCompanies);
           }
          
          } catch (error) {
            console.log(error);
            console.error(error);
          }
        }
        
        fetchData();
      }, []);

  return (
    <div>
 <div className='loader-overlay' id="spin">
                  {isDataLoaded && (
                     <Lottie animationData={button} className='loader'/>
                )}
                  </div>
        <RegisteredCompanies tableData={companies}/>
        {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>
          }
    </div>
  )
}
export default CompanyList