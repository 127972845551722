import React, { useState } from 'react';
import './companySupport.css';
import { getAllowedValuesOfDesignations } from '../../../data/companies';

function Directors({index,id,directors, onDirectorSubmit, onDirectorUpdate,onDirectorRemove}) {
  const designations = getAllowedValuesOfDesignations();

  const [name,setDirectorName] = useState(directors ? directors.name:null)
  const [designation,setDesignation] = useState(directors ? directors.designation:null)

  const handleSubmit = (event) => {
    event.preventDefault();
    const newDirector = { id, name, designation };
    if (directors) {
      onDirectorUpdate(id, newDirector);
    } else {
      onDirectorSubmit(newDirector);
    }
    //setDirectorName("");
    //setDesignation("");
  };
  const handleRemove = () => {
    onDirectorRemove(index); // Call the parent component's remove function
  };

  return (
    <div>
      <form className="d-form">
        <div className="input-container">
          <label htmlFor="">Director Name</label>
          <input type="text"name='name' className='form-input' value={name} onChange={(event) => setDirectorName(event.target.value)}/>
        </div>
        <div className="input-container">
          <label htmlFor="">Designation</label>
          <select name='designation' className='form-input' defaultValue={designation} onChange={(event) => setDesignation(event.target.value)}>
                  {designations.map(item => (
                    <option value={item}>{item}</option>
                  ))}
          </select>  
        </div>
        <button className='remove-btn' onClick={handleRemove}>X</button>
      </form>
    </div>
  );
}

export default Directors