import React, { useState } from 'react';
import '../UserDashboard.css'

const InstructionPopup = ({ onClose, onSave,doNotShowAgain }) => {
  const [originalPassword, setOriginalPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleClick = () => {
    onSave();
  };
  function handleInputChange(val){
    if (val) {
      sessionStorage.setItem("isInsPopupSet",val);
    }
  }
  return (
    <div className="popup">
        <div className="popup-header">
        <h3 className='pwd-popup-heading ins-popup-heading'>Quick guide to generate Minutes of Meetings</h3>
        <button onClick={onClose}>X</button>
        </div>
     <div className="password-popup">
     <div className="input-container popup-text-container">
        <ol>
            <li>Add company in My Clients with necessary details.</li>
            <li>Proceed to Payments tab and add necessary details of the company and it's financial year for which Minutes are to be generated.</li>
            <li>Payment can be made for multiple companies at the same time and for multiple years for single company.</li>
            <li>Once the necessary details are selected, click on Subscribe to make the payment.</li>
            <li>After the payment is successful, user can generate Minutes from Generate Minutes tab.</li>
        </ol>
        <h5>NOTE: Please note that the user can generate Minutes only for those companies whose payment has been completed.</h5>
      </div>
      {/* <input type="checkbox" name="" id="" onChange={(event)=> handleInputChange(event.target.value)}  /> <span>Do not show this message again!</span> */}
      <div className='btn-container ins-btn'>
        <button onClick={handleClick}>Let's EaseOpt</button>
      </div>
    </div>    
      
      
      
    </div>
  );
};

export default InstructionPopup;
