import {
    AutoComplete,
    Button,
      DatePicker,
    Input,
    InputNumber,
    Select,
    Switch,
    Table,
    TimePicker,
    Tooltip,
    Typography,
    Upload,
    Form,
    Popconfirm
  } from "antd";

  import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
  import { useEffect, useState, useCallback } from "react";
  import React, { useContext, useRef } from 'react';
  import moment from 'moment';
  
  const { Option } = Select;
  const { Text } = Typography;
   const { RangePicker } = DatePicker;
 

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  export const AutoCompleteWidget = ({
    props,
    setData,
    optionData,
    placeholder,
  }) => {
    const [val, setVal] = useState();
    const filteredOptionData =
      typeof optionData[0] == "object"
        ? optionData.map((x) => x.label)
        : optionData;
    const setDataInner = (value) => {
      if (value == null) {
        setData(null);
        return;
      }
      if (typeof optionData[0] == "object") {
        setData(optionData.find((x) => x.label === value)?.["value"]);
      } else {
        setData(value);
      }
    };
    return (
      <AutoComplete
        allowClear
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        value={val}
        onChange={(v) => {
          setVal(v);
          if (filteredOptionData.includes(v)) {
            setDataInner(v);
          }
        }}
        options={filteredOptionData.map((c) => {
          return { value: c };
        })}
        onBlur={() => {
          if (filteredOptionData.includes(val)) {
            setDataInner(val);
          } else {
            setDataInner(null);
            setVal(null);
          }
        }}
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        {...props}
      />
    );
  };
  
  export const MultiSelect = ({ OPTIONS, setData, props }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = OPTIONS; // .filter((o) => !selectedItems.includes(o));
    return (
      <Select
        onChange={(val) => {
          setSelectedItems(val);
          setData(val);
        }}
        onKeyDown={handleKeyDown}
        {...props}
        value={selectedItems}
        mode="multiple"
        options={filteredOptions.map((item) => {
          return typeof item === "object"
            ? item
            : {
                value: item,
                label: item,
              };
        })}
      ></Select>
    );
  };
  
  export const SingleSelect = ({ OPTIONS, setData, props }) => {
    useEffect(() => {
      if (!Array.isArray(OPTIONS)) {
        setData(OPTIONS);
      }
    }, []);
    if (!Array.isArray(OPTIONS)) {
      return (
        <Input
          value={OPTIONS}
          onChange={(e) => {
            if (e?.target) {
              setData(e.target.value);
            } else {
              setData(e);
            }
          }}
          onKeyDown={handleKeyDown}
          {...props}
          allowClear
        ></Input>
      );
    }
    return (
      <AutoCompleteWidget
        optionData={OPTIONS}
        setData={setData}
        props={props}
      ></AutoCompleteWidget>
    );
  };
  
  export const STRING_WIDGET = ({ props, setData }) => {
    return (
      <Input
        onKeyDown={handleKeyDown}
        onChange={(e) => setData(e.target.value)}
        {...props}
      ></Input>
    );
  };
  
  export const BOOLEAN_WIDGET = ({ props, setData }) => {
    return <Switch onChange={setData} {...props}></Switch>;
  };
  
  export const INTEGER_WIDGET = ({ props, setData }) => {
    return (
       
      <InputNumber
        onKeyDown={handleKeyDown}
        onChange={setData}
        precision={0}
        min={0}
        {...props}
      ></InputNumber>
    );
  };
  export const INTEGER_WITH_SUFFIX_WIDGET = ({ props, setData }) => {
    return (
      <InputNumber
        onKeyDown={handleKeyDown}
        onChange={setData}
        precision={0}
        min={0}
        {...props}
      ></InputNumber>
    );
  };
  
  export const DECIMAL_WIDGET = ({ props, setData }) => {
    return (
      <InputNumber
        onKeyDown={handleKeyDown}
        onChange={setData}
        precision={3}
        {...props}
      ></InputNumber>
    );
  };

 
  export const DATE_WIDGET = ({ props, setData }) => {
    return (
      <DatePicker
        format={"DD/MM/YYYY"}
        onChange={(date, dateStr) => {
          setData(dateStr);
        }}
        placeholder="E.g. dd/mm/yyyy"
        {...props}
      ></DatePicker>
      
    );
  };
  

  export const DATE_WITH_DAY_WIDGET = ({ props, setData }) => {
    return (
      <DatePicker
        format={"DD/MM/YYYY"}
        onChange={(date, dateStr) => {
          setData(dateStr);
        }}
        placeholder="E.g. dd/mm/yyyy"
        {...props}
      ></DatePicker>
     
    );
  };


  
  export const DATETIME_WIDGET = ({ props, setData }) => {
    return (
      <DatePicker
        onChange={(date, dateStr) => {
          setData(dateStr);
        }}
        showTime={{ format: "hh:mm A", use12Hours: true }}
        format={"DD/MM/YYYY hh:mm A"}
        {...props}
      ></DatePicker>
    );
  };
  
  
  export const TIME_WIDGET = ({ props, setData }) => {
    return (
      <TimePicker
        onChange={(date, dateStr) => {
          setData(dateStr);
        }}
        placeholder="E.g. 03:50 PM"
        use12Hours
        format="hh:mm A"
        {...props}
      ></TimePicker>
    );
  };
  
  export const FIRM_NAME_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = company.name;
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const SECRETARIAL_AUDITORS_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    console.log("company.auditors",company.auditors);
    const OPTIONS = company.auditors
      .filter((x) => x.type === "SECRETARIAL")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const STATUTORY_AUDITORS_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const OPTIONS = company.auditors
      .filter((x) => x.type === "STATUTORY")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const COST_AUDITORS_WIDGET = ({ props, setData, company }) => {
    console.log("company.auditors",company.auditors);
    const OPTIONS = company.auditors
      .filter((x) => x.type === "COST")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };

  //AUDITORS
  export const AUDITORS_WIDGET = ({props, setData, company, type}) =>{
    console.log("company.auditors",company.auditors);
    var OPTIONS;
    switch (type) {
      case "SECRETARIAL":
         OPTIONS = company.auditors
      .filter((x) => x.type === "SECRETARIAL")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
        break;
      case "COST":
         OPTIONS = company.auditors
      .filter((x) => x.type === "COST")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
        break;
      case "STATUTORY":
         OPTIONS = company.auditors
      .filter((x) => x.type === "STATUTORY")
      .map((x) => {
        return { label: x.name, value: x.name };
      });
        break;
    
      default:
        break;
    }
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  }
  
  export const FIRM_ADDRESS_WIDGET = ({ props, setData, company }) => {
    return (
      <Select onChange={setData} {...props}>
        {company.addresses.map((x) => (
          <Option key={x.id} value={x.address}>
            <Tooltip title={x.address}>
              <Text ellipsis={true}>{x.address}</Text>
            </Tooltip>
          </Option>
        ))}
      </Select>
    );
  };
  
  export const LIST_OF_DIRECTORS_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = (company.directors || []).map((x) => {
      return { label: x.name, value: `DIRECTOR_${x.id}` };
    });
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  };
  export const DIRECTOR_WITHOUT_DESIGNATION_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = (company.directors || []).map((x) => {
      return { label: x.name, value: `DIRECTOR_${x.id}` };
    });
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  };
  
  export const LIST_OF_SHARE_HOLDERS_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = (company.shareHolders || []).map((x) => {
      return { label: x.name, value: `SHAREHOLDER_${x.id}` };
    });
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  };
  
  export const LIST_OF_SHARE_HOLDER_AND_DIRECTORS_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const OPTIONS = [
      ...(company.directors || []).map((x) => {
        return { label: x.name, value: `DIRECTOR_${x.id}` };
      }),
      ...(company.shareHolders || []).map((x) => {
        return { label: x.name, value: `SHAREHOLDER_${x.id}` };
      }),
    ];
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  };
  
  export const SHARE_HOLDER_NAME_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = (company.shareHolders || []).map((x) => {
      return { label: x.name, value: `SHAREHOLDER_${x.id}` };
    });
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const DIRECTOR_NAME_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = (company.directors || []).map((x) => {
      return { label: x.name, value: `DIRECTOR_${x.id}` };
    });
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const DIRECTOR_OR_SHARE_HOLDER_NAME_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const OPTIONS = [
      ...(company.directors || []).map((x) => {
        return { label: x.name, value: `DIRECTOR_${x.id}` };
      }),
      ...(company.shareHolders || []).map((x) => {
        return { label: x.name, value: `SHAREHOLDER_${x.id}` };
      }),
    ];
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  export const LIST_OF_SUB_TEMPLATES_WIDGET = ({ props, setData, templates }) => {
    const OPTIONS = (templates || [])
      .filter((x) => x.isSubTemplate === "Y")
      .map((x) => x.name);
    return (
      <MultiSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></MultiSelect>
    );
  };
  
  export const FILE_WITH_TEXT_CONTENT_WIDGET = ({ props, setData }) => {
    const localprops = {
      onRemove: (file) => {
        setData(null);
      },
      beforeUpload: (file) => {
        setData(file);
        return false;
      },
    };
    return (
      <Upload {...localprops} {...props}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    );
  };
  
  export const VOTE_BY_TYPE_WIDGET = ({ props, setData, company }) => {
    return (
      <Select onChange={setData} {...props}>
        {["Unanimously", "By majority"].map((x) => (
          <Option value={x}>
            <Text ellipsis={true}>{x}</Text>
          </Option>
        ))}
      </Select>
    );
  };
  export const TYPE_OF_BUSINESS_WIDGET = ({ props, setData, company }) => {
    return (
      <Select onChange={setData} {...props}>
        {["Ordinary Business", "Special Business"].map((x) => (
          <Option value={x}>
            <Text ellipsis={true}>{x}</Text>
          </Option>
        ))}
      </Select>
    );
  };
  
  export const TRIGGER_WIDGET = ({ props, setData, company }) => {
    const OPTIONS = [
      "10% of turnover",
      "10% of net worth",
      "Exceeding 2.5 lakhs",
      "1% of net worth",
    ];
    return (
      <SingleSelect
        OPTIONS={OPTIONS}
        setData={setData}
        props={props}
      ></SingleSelect>
    );
  };
  
  const AttendanceTable = ({ setData, directorsObj, props, type }) => {
    console.log("director obj",directorsObj)
    const [presentDirectors, setPresentDirectors] = useState([]);
    const [chairmanId, setChairmanId] = useState();
  
    useEffect(() => {
      setData(directorsObj.map((x) => x.attendance));
    }, []);
  
    const onAttendenceChanged = (key, value) => {
      const dir = directorsObj.find((x) => x.key === key);
      dir["attendance"] = `${key}_${value}`;
      console.log("dir_attendace",dir["attendance"]);
      setData(directorsObj.map((x) => x.attendance));
      if (value === "PRESENT") {
        setPresentDirectors({ ...presentDirectors, [key]: true });
      } else {
        setPresentDirectors({ ...presentDirectors, [key]: false });
      }
    };
  
    const chairManSelected = (key, value) => {
      const dir = directorsObj.find((x) => x.key === key);
      if (value) {
        directorsObj.forEach((x, id) => {
          if (x.attendance.includes("CHAIRMAN")) {
            let dirkey = `${x.attendance.split("_")[0]}_${
              x.attendance.split("_")[1]
            }`;
            directorsObj[id]["attendance"] = `${dirkey}_PRESENT`;
          }
        });
        dir["attendance"] = `${key}_PRESENT_CHAIRMAN_SUBSEQUENT`;
        setData(directorsObj.map((x) => x.attendance));
      } else {
        dir["attendance"] = `${key}_PRESENT`;
        setData(directorsObj.map((x) => x.attendance));
      }
      if (value) {
        setChairmanId(key);
      }
    };
  
    const firstTimeSelected = (key, value) => {
      console.log("key in firstTimeSelected",key);
      console.log("value in firstTimeSelected",value);
      const dir = directorsObj.find((x) => x.key === key);
      if (value) {
        dir["attendance"] = `${key}_PRESENT_CHAIRMAN_FIRST`;
        setData(directorsObj.map((x) => x.attendance));
      }
      else{
        dir["attendance"] = `${key}_PRESENT_CHAIRMAN_SUBSEQUENT`;
        setData(directorsObj.map((x) => x.attendance));
      }
    };
    const columns = [
      {
        title:
          type === "director"
            ? "Director Name"
            : type === "shareHolder"
            ? "Shareholder Name"
            : "Director/ShareHolder Name",
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <Tooltip title={text}>
            <Text ellipsis>{text}</Text>
          </Tooltip>
        ),
      },
      {
        title: "Attendance",
        dataIndex: "attendance",
        key: "attendance",
        width: "20%",
        render: (_, record) => {
          return (
            <Select
              onChange={(values) => onAttendenceChanged(record.key, values)}
              style={{ width: "10vw" }}
            >
              <Option value="PRESENT">Present</Option>
              <Option value="ABSENT">Absent</Option>
              <Option value="LEAVEOFABSENCE">Leave Of Absence</Option>
              <Option value="NOTELIGIBLE">Not Eligible</Option>
            </Select>
          );
        },
      },
      {
        title: "Chairperson",
        dataIndex: "Chairperson",
        key: "Chairperson",
        width: "15%",
        render: (_, record) => {
          return (
            <>
              {presentDirectors[record.key] && (
               
                  <Switch
                  checked={record.key === chairmanId}
                  onChange={(values) => {
                    chairManSelected(record.key, values);
                  }}
                />
               
                
              )}
            </>
          );
        },
      },
      {
        title: "New Chairperson?",
        dataIndex: "appChairman",
        key: "appChairman",
        width: "15%",
        render: (key, record) => {
          return (
            chairmanId === record.key && (
              <Tooltip title={"Enable this option only when new chairperson is appointed for this meeting."}>
              <Switch
                onChange={(values) => {
                  firstTimeSelected(record.key, values);
                }}
              ></Switch>
               </Tooltip>
            )
          );
        },
      },
    ];
  
    return (
      <Table
        {...props}
        className="centerdiv"
        size="small"
        bordered={true}
        dataSource={directorsObj}
        columns={columns}
        pagination={{
          pageSize: 5,
        }}
      />
    );
  };
  
  export const DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const [directorsObj, setDirectorsObj] = useState();
    useEffect(() => {
      let directors = [];
      company?.directors?.forEach((d, id) => {
        directors.push({
          key: `DIRECTOR_${d.id}`,
          srno: id + 1,
          id: d.id,
          name: d.name,
          attendance: `DIRECTOR_${d.id}_ABSENT`,
        });
      });
      setDirectorsObj(directors);
    }, []);
  
    return (
      directorsObj && (
        <AttendanceTable
          props={props}
          setData={setData}
          directorsObj={directorsObj}
          type={"director"}
        ></AttendanceTable>
      )
    );
  };
  
  export const SHARE_HOLDER_TABLE_OF_ATTENDANCE_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const [directorsObj, setDirectorsObj] = useState();
    useEffect(() => {
      let directors = [];
      company?.shareHolders?.forEach((d, id) => {
        directors.push({
          key: `SHAREHOLDER_${d.id}`,
          srno: id + 1,
          id: d.id,
          name: d.name,
          attendance: `SHAREHOLDER_${d.id}_ABSENT`,
        });
      });
      setDirectorsObj(directors);
    }, []);
  
    return (
      directorsObj && (
        <AttendanceTable
          props={props}
          setData={setData}
          directorsObj={directorsObj}
          type={"shareHolder"}
        ></AttendanceTable>
      )
    );
  };
  
  export const SHARE_HOLDER_AND_DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET = ({
    props,
    setData,
    company,
  }) => {
    const [directorsObj, setDirectorsObj] = useState();
    useEffect(() => {
      let directors = [];
      company?.directors?.forEach((d, id) => {
        directors.push({
          key: `DIRECTOR_${d.id}`,
          srno: id + 1,
          id: d.id,
          name: d.name,
          attendance: `DIRECTOR_${d.id}_ABSENT`,
        });
      });
      company?.shareHolders?.forEach((d, id) => {
        directors.push({
          key: `SHAREHOLDER_${d.id}`,
          srno: id + 1,
          id: d.id,
          name: d.name,
          attendance: `SHAREHOLDER_${d.id}_ABSENT`,
        });
      });
      setDirectorsObj(directors);
    }, []);
  
    return (
      directorsObj && (
        <AttendanceTable
          props={props}
          setData={setData}
          directorsObj={directorsObj}
          type={"director/shareHolder"}
        ></AttendanceTable>
      )
    );
  };
  
  // const FINANCIAL_YEAR_WIDGET = ({ FY, setData, props }) => {
  //   const OPTIONS = FY;
  //   return (
  //     <Input
  //       setData={setData}
  //       props={props}
        
  //     ></Input>
  //   );
  // };

  export const FINANCIAL_YEAR_WIDGET = ({ props, setData }) => {
    return (
      <Input
      placeholder="E.g. 2023-24"
        onKeyDown={handleKeyDown}
        onChange={(e) => setData(e.target.value)}
        {...props}
      ></Input>
    );
  };
  
  const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};







var finalStringArray = [];
export const RELATED_PARTY_TRANSACTION_TABLE = ({props, setData, company}) => {
debugger;
  // related party array
  const [rptArray,setRelatedPartyArray] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(1);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const OPTIONS = company.relatedParties;
    const defaultColumns = [
    {
      title: 'Related Party',
      width: '30%',
	  render: (_, record) => {
          return (
            <Select onChange={(values) =>handleRelatedPartyChanged(record.key,values)}  style={{ width: "8vw" }} id={"relatedParty_"+{count}}>
        {OPTIONS.map((x,index) => (
          <Option key={index.toString()} value={x.id}>
            <Text ellipsis={true}>{x.name}</Text>
          </Option>
        ))}
      </Select>
          );
        },
    },
    {
      title: 'Relation',
      // dataIndex: 'age',
	  render: (_, record) => {
      console.log("record",record);
          return (
            <Text  id={"relation_"+record.key}/>
          );
        },
    },
    {
      title: 'Nature of transaction',
	  render: (_, record) => {
          return (
            <Input type="text" id={"natureOfTransaction_"+{count}} onBlur={(event) => handleNatureOfTransactionChanged(event)}/>
          );
        },
    },
    {
      title: 'Trigger',
	   render: (_, record) => {
          return (
            <Select
               onChange={(values) => handleTransactionChanged(record.key, values)}
              style={{ width: "8vw" }}
              id={"trigger_"+{count}}
            >
              <Option value="10% of Turnover">10% of Turnover </Option>
               <Option value="10% of Net worth">10% of Net worth</Option>
             <Option value="monthly remuneration Rs. 2.5 Lakhs">monthly remuneration Rs. 2.5 Lakhs</Option>
               <Option value="1% of Net Worth">1% of Net Worth</Option>
            </Select>
          );
        },
    },
    {
      title: 'Action',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
           <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];

  function handleRelatedPartyChanged(key,value){
        OPTIONS.forEach((element,index) => {
          if (element.id == value) {
            document.getElementById("relation_"+key).innerHTML = element.natureOfRelation;
          }
             });
        finalStringArray.push(value);
    }

    function handleNatureOfTransactionChanged(event){
        finalStringArray.push(event.target.value)
      }
      function handleTransactionChanged(key,value){
          finalStringArray.push(value);
            var rptString = ""
            finalStringArray.forEach(element => {
              rptString += element + "_"; 
                  
            });
  rptString = rptString.substring(0,rptString.length-1);
  if (rptArray.length ==0) {
    console.log("inside if");
   rptArray.push(rptString)
   //setRelatedPartyArray([...rptArray,rptString])
  }
  else{
    console.log("inside else: rpt array",rptArray);
    rptArray.push(rptString)
    // setRelatedPartyArray([...rptArray,rptString])
  }
  
 // setRelatedPartyArray([...rptArray,rptString])
          setData(rptArray);
           rptString = "";
           finalStringArray =[]
      }



    const handleAdd = () => {
    const newData = {
      key: count,
    };
    setDataSource([ ...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      ...index
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Text className="table-text"> Note: Kindly fill one row at a time.</Text>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        size="small"
        key={"related party table"}
      />
    </div>
  );
}


var date;
export const MyDatePicker = ({props, setData, company,fy=""}) => {
  console.log("fy",fy=="");
  if (fy == "") {
    fy="1800-90"
  }
  const partsArray = fy.split('-');
   console.log(partsArray);
   partsArray[1] = "20"+partsArray[1];
   console.log(partsArray);
   var fromDate = partsArray[0]+"-" + "04-01"
   var toDate = partsArray[1]+"-" + "03-31"
  const startDate = moment(fromDate);//derive these from financial year
  const endDate = moment(toDate);
  const disabledDate = (current) => {
	return current && (current < startDate || current > endDate);
  }
  
  return (
    <div>
      {/* Use RangePicker and pass the disabledDate function */}
      <DatePicker disabledDate={disabledDate}  format={"DD/MM/YYYY"}
        onChange={(date, dateStr) => {
          setData(dateStr);
        }}
        placeholder="E.g. dd/mm/yyyy"
        {...props}/>
    </div>
  );
};
