import React , { useEffect, useState } from 'react'
import { Button, Card, Tooltip, Space, Table, Typography, Input,Spin } from "antd";
import { FormOutlined, EditOutlined, DeleteOutlined,DownloadOutlined } from "@ant-design/icons";
import './UserDashboard.css'
import Popup from './supportingComponents/Popup';
import { post , get } from '../../context/rest';
import { Link } from "react-router-dom";
import Search from "antd/es/transfer/search";
import { getTemplate, getTemplates } from '../../data/templates';
import { previewDocument, saveDocument } from "../../data/documents";
import { download } from "../../context/rest";
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';
import InstructionPopup from './supportingComponents/InstructionsPopup';
import { useNavigate } from "react-router-dom";
import SessionExpiredPopup from "../SessionExpiredPopup";
const { Text } = Typography;
function UserDashboard() {
    var username  = sessionStorage.getItem("userName")
    const navigate = useNavigate();
    var userId = 1000;
    const [user, setUser] = useState({})
    const[documents,setDocuments] = useState([])
    const[templates,setTemplates] = useState([])
    const[isDataLoaded,setIsDataLoaded] = useState(true);
    const[isYearlySubscribed,setIsYearlySubscribed] = useState();
    const[yearlyResponseData,setYearlyResponseData] = useState();
    const[downloadBalance,setDownloadBalance] = useState();
    
  const [showPopup, setShowPopup] = useState(false);
  const closePopup = () => {
    navigate("/login");
    setShowPopup(false);
  };

    var firmId = sessionStorage.getItem('firmId')
    var email = sessionStorage.getItem('userId')
    var path = `appUser/user/getByEmail?email=${email}`
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await get(`document/user/getAllForFirm?firmId=${firmId}`);
            const resp = await get('payment/getYearlySubscriptionDetails');
            const yearlyData = await get('payment/getBalanceClientsFYCombinations');
            setDownloadBalance(yearlyData);
            console.log("response",resp);
            if(resp.length > 0 ){
              sessionStorage.setItem("isYearlySubscribed",true);
             // Assuming this is your initial setup
            var endDate = new Date(resp[0].endDate);
            var maxEndDateElement = resp[0]; // Assuming the first element initially has the maximum end date

            // Loop through the array to find the element with the maximum end date
            resp.forEach((element) => {
                var date = new Date(element.endDate);
                if (date > endDate) {
                    endDate = date;
                    maxEndDateElement = element;
                }
            });
            setYearlyResponseData(maxEndDateElement);
            }
            else{
              setIsYearlySubscribed(false);
              sessionStorage.setItem("isYearlySubscribed",false);
            }
              setIsDataLoaded(false)
              document.getElementById('spin').classList.remove('loader-overlay')
            var usr = await get(path);
            setUser(usr);
            if (response.unauthorized) {
              setShowPopup(true);
            } else {
              setDocuments(response);
            }
            
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);


    // change password popup
    const [searchText, setSearchText] = useState("");
    const [isPopupOpen, setPopupOpen] = useState(false);
     const [isInsPopupOpen, setInsPopupOpen] = useState();
    const [passwordData, setPasswordData] = useState([]);
    const [template, setTemplate] = useState();
    const [dataSource, setDataSource] = useState(documents);
  
    const handleOpenPopup = async () => {
      setPopupOpen(true);
    
    };
  
    const handleClosePopup = () => {
       setPopupOpen(false);
    };
    const handleInsClosePopup = () => {
      setInsPopupOpen(false);
    };
    const handleSavePassword = async (data) => {
      setPasswordData([...passwordData, data]);
      console.log(data);
      // var checkPassword = `appUser/admin/overridePassword?userId=${user.id}&password=${data.originalPassword}`;
      var updatePassword = `appUser/user/updatePassword?userId=${user.id}&existingPassword=${data.originalPassword}&newPassword=${data.newPassword}`;
      
        var res = await post(updatePassword);
        console.log(res);
        document.getElementById('passwordMessage').value = "Password Updated Successfully!"
     
      //handleClosePopup();
    };

    
    //downloadDocument
    async function downloadDocument(rec) {
      var date = new Date();
      var filename = date.current ? date.current : rec.clientFirm.name
      var path = `document/user/download?id=${rec.id}`;
      var res = await download(path,rec,filename);
    }
    const columns = [
      {
         title: 'Document Name',
        dataIndex: "name",
        key: "name",
        // sorter: (a, b) => a.name > b.name,
        render: (text) => (
          <Tooltip title={text}>
            <label ellipsis={true}>{text}</label>
          </Tooltip>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "20%",
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title="Download Document">
                <DownloadOutlined  className='icon' onClick={(rec) => downloadDocument(record)}/>
            </Tooltip>
          </Space>
        ),
      },
    ];

    var fromMail = false;
  return (
    <div className='list-container'>
       <div>
                    <span className="heading">
                <h3>Hello, {username}</h3>
                </span>
                </div>
             {yearlyResponseData != undefined &&
            <div className='data-heading-container'>
              
                {/* <div className="card-container"><h4>No Of meetings</h4><p className="data-text">{yearlyResponseData.numberOfMeetings}</p></div> */}
                {/* <div className="card-container"><h4>No Of Client Combinations</h4><p className="data-text">{yearlyResponseData.numberOfClientFyCombination}</p></div> */}
                <div className='data-card-container'><span className="data-text">Subscription Till : {yearlyResponseData.endDate[2] +"-"+ yearlyResponseData.endDate[1] +"-" +yearlyResponseData.endDate[0]}</span>
                <span className="data-text">Download Balance : {downloadBalance}</span></div>
            </div>
              }
            <div className="header-container user-dashboard-header">
                
               <div><h3>Generated Documents</h3></div>
                {/* <button className='btn-primary' onClick={handleOpenPopup}>Change Password</button> */}
                <div className='nw-btn-lnk'>
                <Link className='link'  to={`/updatePassword`}>Change Password</Link>
                </div>
                
                
                {isPopupOpen && (
                    <div className="popup-overlay">
                    <Popup onClose={handleClosePopup} onSave={handleSavePassword} />
                    </div>
                )}
                {isInsPopupOpen && (
                    <div className="popup-overlay">
                    <InstructionPopup onClose={handleInsClosePopup} onSave={handleInsClosePopup}/>
                    </div>
                )}
                
            </div>
            {/* {  var endDate = yearlyResponseData[0].endDate[2] +"/"+ yearlyResponseData[0].endDate[1] +"/" +yearlyResponseData[0].endDate[0]} */}
           
            <div className='user-history'>
                  <div className='loader-overlay' id="spin">
                  {isDataLoaded && (
                     <Lottie animationData={button} className='loader'/>
                )}
                  </div>
                 
              <div className='historyTable'>
             


                    <Table
                    className="centerdiv"
                    sticky={true}
                    size="middle"
                    bordered={true}
                    dataSource={documents}
                    columns={columns}
                    pagination={{
                      pageSize: 10,
                    }}
                    
                  />
              
             
              </div>
            </div>
            {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>
          }
    </div>
  )
}

export default UserDashboard