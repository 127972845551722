import { download, get, post, preview, uploadWithForm } from "../context/rest";

const getTemplateTypes = () => {
  return get(`document/user/getAllDocumentTypes`);
};

const getDocuments = (firmId) => {
  if (!firmId) return;
  return get(`document/user/getAll?firmId=${firmId}`);
};

const getDocument = (id) => {
  if (!id) return;
  return get(`document/user/get?clientFirmId=${id}`);
};

const deleteDocument = (id) => {
  if (!id) return;
  return post(`document/user/delete`, { id });
};

const saveDocument = (clientFirmId, template, filename) => {
  download(
    `document/generate?clientFirmId=${clientFirmId}`,
    JSON.stringify(template),
    filename
  );
};

const previewDocument = (clientFirmId, template, filename) => {
  var res = preview(
    `document/preview?clientFirmId=${clientFirmId}`,
    JSON.stringify(template),
    filename
  );
  return res;
};

export {
  getTemplateTypes,
  getDocuments,
  getDocument,
  saveDocument,
  deleteDocument,
  previewDocument,
};
