import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Table,
  Popconfirm,
  Alert, Spin, Switch
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { getCompanies, getAllCompanies } from "../../data/companies";
import { get, post } from "../../context/rest";
import { Link, useNavigate } from "react-router-dom";
import SessionExpiredPopup from "../SessionExpiredPopup";
import "./Subscription.css";
import { useRef } from "react";
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';
const { Option } = Select;

const DynamicTable = () => {

  const ref = useRef(null);
  const closePopup = () => {
    navigate("/login");
    setShowPopup(false);
  };
  
  const [isOfferMsgSet, setOfferMsg] = useState(false);
  const [isFreeSubMsgSet, setFreeSubMsg] = useState(false);
  const[resAfterAdd,setResponseAfterAdd] = useState();
  const[paymentInitiatedButNotCompleted,setPaymentInitiatedButNotCompleted] = useState(false);
  const[paymentFailed,setPaymentFailed] = useState(false);
  const[paymentSuccessful,setPaymentSuccessful] = useState(false);
  const [isStatusResponseReceived, setIsStatusResponseReceived] = useState(true);

  const navigate = useNavigate();



  const [companies, setCompanies] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    console.log("isStatusResponseReceived",isStatusResponseReceived);
    var firmId = sessionStorage.getItem("firmId");
    async function fetchData() {
      try {
        // After Transaction 

        if (queryParams.has("transactionId")) {
          var tid = sessionStorage.getItem('tid');
          var type = sessionStorage.getItem("isTrial")
          if (type == "true") {
            var statusResponse =await get(
              `payment/checkPaymentStatus?transactionId=${tid}`
            )
            console.log("status response:",statusResponse);
            
            setIsStatusResponseReceived(false);
            {isStatusResponseReceived == 'false' && console.log("bye");}
          }
          else if (type == "false"){
            console.log("yearly");
            var statusResponse = await get(
              `payment/checkPaymentStatusForYearlySubscription?transactionId=${tid}`
            );
            if (statusResponse == 'PAYMENT_INITIATED') {
              setPaymentInitiatedButNotCompleted(true)
            }
            else if(statusResponse == 'PAYMENT_INITIATED'){
              setPaymentFailed(true)
            }
            else if(statusResponse == 'PAYMENT_SUCCESSFUL'){
              setPaymentSuccessful(true)
            }
            console.log("status response:",statusResponse);
          }
        }

        // Before Transaction
        var res = await getAllCompanies(firmId);
        var subscription = await get("payment/getPaymentDetails");
        setSubscriptions(subscription);
        if (res.unauthorized) {
          setShowPopup(true);
        } else {
          setCompanies(res);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);
  const redirectToExternalURL = (url) => {
    window.open(url); // Replace with your desired external URL
  };

  const initiatePayment = async () => {
    var amt = document.getElementById("tAmount").value;
    var userId = sessionStorage.getItem("userId");
    var listOfPayments = [];
    var fId = sessionStorage.getItem("firmId");

    data.forEach((element) => {
      const date = new Date();
      var formData = {
        amount: element.amount,
        clientFirm: { id: element.companyId },
        financialYear: element.financialYear,
        firmId: parseInt(fId),
        numberOfMeetings: element.boardMeetings,
        paymetnDate: date,
        status: "NEW",
      };
      listOfPayments.push(formData);
    });
    var res = await post(
      `payment/addPaymentDetails?userId=${userId}`,
      listOfPayments
    );

    setResponseAfterAdd(res);

    if (res) {
      var response = await post(
        `payment/initiatePayment?userId=${userId}`,
        res
      );
      sessionStorage.setItem("isTrial",true)
      sessionStorage.setItem("tid",response.transactionId);
      if (response.paymentRedirectUrl == null) {
        setFreeSubMsg(true);
        setOfferMsg(false);
      } else {
        redirectToExternalURL(response.paymentRedirectUrl);
      }
    }
  };


  const onBoardMeetingsChange = (boardMeetings) => {
    const amount = calculateAmount(boardMeetings);
    form.setFieldsValue({ amount }); // Set the "Amount" field value in the form
  };

  function checkLimit(params) {
    if (params.target.value > 30) {
      document.getElementById("maxMeetingMsg").classList.remove("hideMsg");
      document.getElementById("meetings").value = "";
    }
  }

  //  new
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Financial Year",
      dataIndex: "financialYear",
      key: "financialYear",
    },
    {
      title: "No. of Board Meetings",
      dataIndex: "boardMeetings",
      key: "boardMeetings",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "actions",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this row?"
          onConfirm={() => handleDelete(record.key)}
        >
          <Button type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const prevTransactions = [
    {
      title: "Company Name",
      dataIndex: "clientFirm.name",
      key: "name",
      render: (text, record) => record.clientFirm.name,
    },
    {
      title: "Financial Year",
      dataIndex: "financialYear",
      key: "financialYear",
    },
    {
      title: "No. of Board Meetings",
      dataIndex: "numberOfMeetings",
      key: "numberOfMeetings",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const handleDelete = (key) => {
    setData(data.filter((item) => item.key !== key));
  };

  const calculateAmount = (boardMeetings) => {
    switch (boardMeetings) {
      case "15":
        return 199;
      case "30":
        return 349;
      case "31":
        return 549;
      default:
        return 0; // Handle other cases if needed
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const onFinish = (values) => {
    const company = companies.find((c) => c.id == values.companyName);
    var amount = calculateAmount(values.boardMeetings);
    // check if company has subscription if not and amount is 199 then total cost will be 0

    var clientFirms = [];
    subscriptions.forEach((element) => {
      clientFirms.push(element.clientFirm.id);
    });

    if (amount == 199 && clientFirms.length == 0) {
      if (data.length == 0) {
        amount = 0;
        setOfferMsg(true);
      } else {
        const hasAmountZero = data.some((obj) => obj.amount === 0);
        if (!hasAmountZero) {
          amount = 0;
          setOfferMsg(true);
        }
      }
    } else if (amount == 199 && clientFirms.includes(company.id) == -1) {
      if (data.length == 0) {
        amount = 0;
        setOfferMsg(true);
      } else {
        const hasAmountZero = data.some((obj) => obj.amount === 0);
        if (!hasAmountZero) {
          amount = 0;
          setOfferMsg(true);
        }
      }
    }

    const newData = {
      key: new Date().getTime(),
      ...values,
      amount,
      companyName: company ? company.name : "",
      companyId: company.id,
    };
    setData([...data, newData]);
    form.resetFields();
    setIsButtonDisabled(false);
  };

  const calculateTotalAmount = () => {
    var amt = 0;
    var finalAmt;
    var cnt = 0;
    data.forEach((element) => {
      amt = amt + element.amount;
    });
    return amt;
  };
  const [tableVisible, setTableVisible] = useState(false);

  const toggleTable = () => {
    setTableVisible(!tableVisible);
  };

  // Yearly subscription
  function yearlySubscriptionPackageOne() {
    var amount = 1699;
    var cos = 100;
    addPaymentDetails(amount, cos);
  }
  function yearlySubscriptionPackageTwo() {
    var amount = 7999;
    var cos = 2500;
    addPaymentDetails(amount, cos);
  }
  function yearlySubscriptionPackageThree() {
    var amount = 14999;
    // var cos = 1000000000;
    var cos = 10000;
    addPaymentDetails(amount, cos);
  }

  async function addPaymentDetails(amount, cos) {
    var userId = sessionStorage.getItem("userId");
    var fId = sessionStorage.getItem("firmId");
    const date = new Date();
    var formData = {
      firmId: parseInt(fId),
      amount: amount,
      status: "NEW",
      startDate: date,
      endDate: calculateEndDate(date),
      numberOfClientFyCombination: cos,
      numberOfMeetings: calculateNoOfMeetings(cos),
    };
    console.log("yearly data",formData);
    var res = await post(
      `payment/addYearlyPaymentDetails?userId=${userId}`,
      formData
    );
    sessionStorage.setItem("tid",res.transactionId)
    sessionStorage.setItem("isTrial",false)
    if (res.isAnnualPaymentResponseSuccess == false) {
      alert("Something went wrong!");
    } else {
      redirectToExternalURL(res.paymentRedirectUrl);
      // if (checkPaymentStatus) {
      //   console.log("hey0 from if");
      //     var statusResponse = get(
      //   `payment/checkPaymentStatusForYearlySubscription?transactionId=${res.transactionId}`,
      //   res
      // );
      // console.log(statusResponse);
      // }
      
    }
  }

  function calculateNoOfMeetings(cos) {
    return 15 * cos;
  }

  function calculateEndDate(date) {
    
      var year = date.getFullYear();
      var dt = date.getDate();
      var month = date.getMonth();
      var newYear = year + 1;
      var endDate =  newYear + '/' + (month+1) + '/' + dt;
      var ed = new Date(endDate);
      return ed;
    
  }

  const [isTrialPackageSelected, setIsTrialPackageSelected] = useState();
  function displayForm(event, val) {
    if (val) {
      setIsTrialPackageSelected(true);
    } else {
      setIsTrialPackageSelected(false);
    }
  }

  function yearlySubscriptionPackageTrial() {
    setIsTrialPackageSelected(true);
    ref.current.classList.add("trial-form");
    ref.current.classList.remove("trial-form-hidden");
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div>
      <div className="subsr-form-container">
        <h2>Let's EaseOpt</h2>
        <p>Make Your Payments Here</p>
        <div>
          <div>
            {paymentSuccessful && <div className='payment-message-container success-message'>Payment Successful! Enjoy EaseOpt!</div>}
            {paymentInitiatedButNotCompleted && <div  className='payment-message-container warning-message'>Payment Initiated will take some time to reflect.</div>}
            {paymentFailed && <div  className='payment-message-container failed-message'>Payment Failed! Try again</div>}
          </div>
          <div>
            <div className="yearly-packages">
              <div className="package-container">
                <h2 className="package-heading">Trial</h2>
                <div className="message-container">
                  <p className="payment-message">
                    Navigate through the software to get started
                  </p>
                  <p className="price-message">Rs 199/ company</p>
                  <p>
                    <b>Minutes for 1 Financial Year</b>
                  </p>
                </div>
                <br></br>
                <Button
                  className="subscribe-btn"
                  onClick={yearlySubscriptionPackageTrial}
                  style={{ backgroundColor: "#138ead", color: "white" }}
                >
                  Subscribe
                </Button>
                <hr />
                <ul className="package-desc">
                  <b>
                    <span className="features-heading">Salient features</span>
                  </b>
                  <li>Most opted by small scale users</li>
                  <li>
                    Pay for what you use- pay only for the companies you
                    currently prepare Minutes for
                  </li>
                  <li>Access for 365 days</li>
                </ul>
              </div>
              <div className="package-container">
                <h2 className="package-heading">Basic</h2>
                <div className="message-container">
                  <p className="payment-message">
                    Get started with the beginner set of minutes generation
                  </p>
                  <p className="price-message">Rs 1,699/ year</p>
                  <p>
                    <b>Minutes for 10 Financial Years</b>
                  </p>
                </div>
                <br></br>
                <Button
                  className="subscribe-btn"
                  onClick={yearlySubscriptionPackageOne}
                  style={{ backgroundColor: "#138ead", color: "white" }}
                >
                  Subscribe
                </Button>
                <hr />
                <ul className="package-desc">
                  <b>
                    <span className="features-heading">Salient features</span>
                  </b>
                  <li>Best suited for new user</li>
                  <li>
                    Generate minutes for 10 financial years of any combination
                  </li>
                  <li>Access for 365 days</li>
                  <li>Carryover credit on package renewal</li>
                </ul>
              </div>
              <div className="package-container standard-package">
              <span className="best-selling-heading">Best Selling</span>
                <h2 className="package-heading">Standard</h2>
                <div className="message-container">
                  <p className="payment-message">Step up for more benefits</p>
                  <br></br>
                  <p className="price-message">Rs 7,999/ year</p>
                  <p>
                    <b>Minutes for 50 Financial Years</b>
                  </p>
                </div>
                <br></br>
                <Button
                  className="subscribe-btn"
                  onClick={yearlySubscriptionPackageTwo}
                  style={{ backgroundColor: "#138ead", color: "white" }}
                >
                  Subscribe
                </Button>

                <hr />
                <ul className="package-desc">
                  <b>
                    <span className="features-heading">Salient features</span>
                  </b>
                  <li>Most preferred package by users</li>
                  <li>
                    Generate minutes for 50 financial years of any combination
                  </li>
                  <li>Access for 365 days</li>
                  <li>Carryover credit on package renewal</li>
                </ul>
              </div>
              <div className="package-container">
                <h2 className="package-heading">Elite</h2>
                <div className="message-container">
                  <p className="payment-message">
                    Choose premium software to enhance efficiency
                  </p>
                  {/* <br></br> */}
                  <p className="price-message">Rs 14,999/ year</p>
                  <p>
                    {" "}
                    <b>Unlimited Minutes Generation for companies</b>
                  </p>
                </div>
                <Button
                  className="subscribe-btn"
                  onClick={yearlySubscriptionPackageThree}
                  style={{ backgroundColor: "#138ead", color: "white" }}
                >
                  Subscribe
                </Button>
                <hr />
                <ul className="package-desc">
                  <b>
                    <span className="features-heading">Salient features</span>
                  </b>
                  <li>
                    Generate unlimited minutes at the cost of 75 companies only
                  </li>
                  <li>Unlimited downloads and documents</li>
                  <li>Access for 365 days</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trial Form */}

      {isFreeSubMsgSet && (
        <div
          style={{
            textAlign: "center",
            margin: "1rem auto",
            backgroundColor: "lightgreen",
            width: "100%",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          {" "}
          <label
            style={{ fontSize: "16px", color: "green", fontWeight: "bold" }}
            id="msg"
          >
            Congratulations! Your subscription has been successfully processed.
          </label>
        </div>
      )}

      <div id="trial-form" ref={ref} className="trial-form-hidden">
        {isTrialPackageSelected && (
          <div>
            <h2>Get Trial Package for Specific Companies</h2>
            <div>
              <Form
                form={form}
                onFinish={onFinish}
                layout="inline"
                className="payment-form"
              >
                <div className="input-container">
                  <label htmlFor="">Company Name</label>
                  <Form.Item name="companyName" required>
                    <Select>
                      {companies.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-container">
                  <label htmlFor="">Financial Year</label>
                  <Form.Item name="financialYear" required>
                    <Input />
                  </Form.Item>
                </div>
                <div className="payment-cntr">
                  <div className="input-container">
                    <label htmlFor="">No. of Board Meetings</label>
                    <Form.Item name="boardMeetings" required>
                      <Select onChange={onBoardMeetingsChange}>
                        <Option value="15">15</Option>
                        <Option value="30">30</Option>
                        <Option value="31">30 and above</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="input-container">
                    <label htmlFor="">Charges</label>
                    <Form.Item name="amount" required>
                      <Input readOnly />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className="button-div">
                    <Button type="primary" htmlType="submit" className="button">
                      Add
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <label htmlFor="" style={{ width: "30%", fontSize: "18px" }}>
                  Grand Total
                </label>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Rs.
                </span>
                <Input
                  type="text"
                  value={calculateTotalAmount()}
                  id="tAmount"
                  readOnly
                  style={{
                    border: "none",
                    marginTop: "-8px",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                />
              </div>
              {isOfferMsgSet && (
                <div
                  style={{
                    textAlign: "center",
                    margin: "1rem auto",
                    backgroundColor: "lightgreen",
                    width: "100%",
                    borderRadius: "5px",
                    padding: "1rem",
                  }}
                >
                  {" "}
                  <label
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontWeight: "bold",
                    }}
                    id="msg"
                  >
                    You just availed our special offer!
                  </label>
                </div>
              )}

              {data.length > 0 && (
                <div
                  style={{ marginTop: "16px", textAlign: "right" }}
                  className="button"
                >
                  <Button
                    type="primary"
                    onClick={initiatePayment}
                    disabled={isButtonDisabled}
                    style={{ backgroundColor: "green" }}
                  >
                    Subscribe
                  </Button>
                </div>
              )}
              <h4 style={{ marginTop: "16px" }}>Selected Financial Years</h4>
              <Table
                style={{ marginTop: "16px" }}
                columns={columns}
                dataSource={data}
                pagination={{
                  pageSize: 5,
                }}
                rowKey={(record) => record.key}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <Button
          onClick={toggleTable}
          type="primary"
          style={{
            backgroundColor: "#138ead",
            marginLeft: "80%",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          {tableVisible ? "Hide" : "Show Previous Subscriptions"}
        </Button>
        {tableVisible && (
          <Table
            className="pre-table-hidden pre-table"
            id="data-table"
            columns={prevTransactions}
            dataSource={subscriptions}
            pagination={{
              pageSize: 5,
            }}
            rowKey={(record) => record.key}
          />
        )}
      </div>
      {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>
          }
    </div>
  );
};

export default DynamicTable;
