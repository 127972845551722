import React from 'react'

const Terms = () => {
  return (
    <div style={{width:"80%",margin:"auto"}}>
        <p><strong>&nbsp;</strong></p>
<p><strong>EASEOPT SOLUTIONS TERMS OF USE</strong></p>
<p><span style={{fontWeight: "400"}}>This page states the terms and conditions governing your use of the application EASEOPT SOLUTIONS.</span></p>
<p><span style={{fontWeight: "400"}}>Please read them carefully. These terms and conditions may be updated and amended by us from time to time, it is advised to please visit this page periodically to review most current terms of use of this website.</span></p>
<p><span style={{fontWeight: "400"}}>By using this site, you signify that you agree with these terms of use. If you do not accept the following terms and conditions, do not use this site or the services or features provided therein.</span></p>
<p><span style={{fontWeight: "400"}}>In addition, when you use any current or future service or purchase any current or future product on this site, you will also be subject to the applicable terms and conditions of sale.</span></p>
<ol>
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>ACCEPTANCE OF TERMS OF USE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>Welcome to EaseOpt website (hereinafter &ldquo;the Site&rdquo;), a service provided by EaseOpt Solutions Private Limited and its affiliates (hereinafter &ldquo;We&rdquo;, &ldquo;Our&rdquo; or &ldquo;Us&rdquo;). By visiting the Site, by providing Us with information, by using the services offered by the Site and/or by purchasing products from the Site, you agree to be bound by the following terms and conditions (&ldquo;Terms of Use&rdquo;). If you do not agree with any of these terms, then please do not use the Site.</span></p>
<p><span style={{fontWeight: "400"}}>By visiting and using the services offered by this Website, you also agree to the terms of Use and other terms and conditions of Hostinger Website (Our Web Hosting Service Provider) and that we shall not take any responsibility for your failure to understand the terms of service offered by the said website</span><span style={{fontWeight: "400"}}>.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="2">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>DESCRIPTION OF SERVICE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>The Site is offered to you, the user (hereinafter &ldquo;User(s)&rdquo;, &ldquo;You&rdquo; or &ldquo;Your&rdquo;) who connects to the Site and uses Our services enabling access to a variety of resources, including download areas, product information, support services for customers and partners, other information, and online purchasing of some of Our products or services (hereinafter the &ldquo;Service&rdquo;).</span></p>
<p><span style={{fontWeight: "400"}}>You are responsible for obtaining access to the Service. Please note that such access may involve third-party fees (such as Internet service provider charges). In addition, You must provide and are responsible for the equipment necessary to access the Service.</span></p>
<p><span style={{fontWeight: "400"}}>We will endeavour to allow uninterrupted access to the Site. However, access to the Site may be suspended, restricted, or terminated at any time and without notice and We shall not be liable if for any reason the Site is unavailable at any time or for any period.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="3">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>REGISTRATION &ndash; PASSWORD &ndash; SECURITY</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>In order to fully use the Site and buy Our products as applicable, You may need to register with Us by opening a free of charge account.</span></p>
<p><span style={{fontWeight: "400"}}>In consideration of Your registration to the Service, You represent that You are at least 18 years of age, that You have the legal right to accept these Terms of Use, including the right to do so on behalf of Your organization if You use the Service for professional purposes or on behalf of your company, and are not barred from receiving products and services under the laws of any applicable jurisdiction.</span></p>
<p><span style={{fontWeight: "400"}}>In so doing, You will be prompted to provide certain information and to create an account name and password. You agree to (a) provide true, accurate, current and complete information about Yourself; and (b) maintain and promptly update Your profile to keep it true, accurate, current and complete.</span></p>
<p><span style={{fontWeight: "400"}}>You are fully responsible for maintaining the confidentiality of Your password and account for all activities that occur under Your password or account.</span></p>
<p><span style={{fontWeight: "400"}}>EaseOpt&rsquo;s Privacy Policy, available at the home page </span><span style={{fontWeight: "400"}}>(address on website where policy shall be hosted)</span><span style={{fontWeight: "400"}}> will govern the use of Your personal data.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="4">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>YOUR CONDUCT WHEN USING THE SITE OR THE SERVICE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>You understand that any content you may post or send using this site is of your sole and unique responsibility and that You are solely responsible for your conduct on the Site.</span></p>
<p><span style={{fontWeight: "400"}}>This means that only You, and not Us, are responsible for all content that You may upload, post, license, sublicense, display or otherwise make available via the Service or the Site. EaseOpt does not control the content posted via the Site or Service and, as such, may not guarantee the accuracy, integrity, or quality of such content or that it does not violate any laws or rights of others.</span></p>
<p><span style={{fontWeight: "400"}}>You further acknowledge that:</span></p>
<ul>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>You will not upload, reproduce, post, display, license, sublicense or otherwise make available any content that is offensive, inappropriate, unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable or contrary to all applicable laws and regulations;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Harm minors in any way;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content uploaded through the Service or the Site;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Upload, reproduce, post, display, license, sublicense or otherwise make available any content that (i) you do not have a right to make available under any law or under contractual or fiduciary relationships, (ii) infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party, or (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>To interfere with or disrupt the Site, the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Modify, adapt or hack the Site or modify another website so as to falsely imply that it is associated with the Site;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Use any elements available on the Site (including, without limitation, textures files) except if specifically authorized on the Site or through a separate license;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Obtain or attempt to obtain any materials or information through any means not intentionally made available through the Service;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Stalk or otherwise harass another;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>&nbsp;</span><span style={{fontWeight: "400"}}>Create, send or submit unwanted email to any other user of the Site or Service;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>&nbsp;</span><span style={{fontWeight: "400"}}>Create, send or submit unwanted email to any other user of the Site or Service;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>Collect or store personal data about other users of the Site or Services where relevant in connection with the prohibited conduct and activities set forth above;</span></li>
<li style={{fontWeight: "400"}}><span style={{fontWeight: "400"}}>and/or violate in any manner any applicable laws.</span></li>
</ul>
<p><span style={{fontWeight: "400"}}>You acknowledge, consent and agree that (i) We may remove any content that violate the Terms of Use and that (ii) We may access, preserve and disclose your account information, if any, and content if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to:</span></p>
<p><span style={{fontWeight: "400"}}>-</span><span style={{fontWeight: "400"}}>&nbsp; &nbsp; &nbsp; &nbsp; </span><span style={{fontWeight: "400"}}>Comply with legal obligations;</span></p>
<p><span style={{fontWeight: "400"}}>-</span><span style={{fontWeight: "400"}}>&nbsp; &nbsp; &nbsp; &nbsp; </span><span style={{fontWeight: "400"}}>Enforce the Terms of Use;</span></p>
<p><span style={{fontWeight: "400"}}>-</span><span style={{fontWeight: "400"}}>&nbsp; &nbsp; &nbsp; &nbsp; </span><span style={{fontWeight: "400"}}>Respond to claims that any content violates the rights of third parties; or</span></p>
<p><span style={{fontWeight: "400"}}>-</span><span style={{fontWeight: "400"}}>&nbsp; &nbsp; &nbsp; &nbsp; </span><span style={{fontWeight: "400"}}>Protect the rights, property or personal safety of any person.</span></p>
<p><span style={{fontWeight: "400"}}>By posting or sending any content to the Sites or Blogs, You grant EaseOpt a worldwide, royalty-free, sub-licenseable, transferable and non-exclusive license to use, copy, distribute, reproduce, modify, translate, adapt to EaseOpt standards and publicly display the content in connection with the promotion of EaseOpt&rsquo;s products and services, for the duration of the legal protection of the intellectual property rights.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="5">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>LINKS TO THIRD PARTY WEBSITES</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>We have no control over any of the third party websites to which the Site has links nor do We contribute in any manner to these third party websites and We assume no responsibility for the contents of any third party websites to which the Site has links.</span></p>
<p><span style={{fontWeight: "400"}}>These links are provided solely as a convenience to You.&nbsp; If You use these links, You will leave the Site. We exclude all liability arising therefrom and do not endorse or make any representations about them or any information, software or other products or materials found there, or any results that may be obtained by using them. If You decide to access any third party websites linked to the Site, You do so entirely at Your own risk.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="6">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>LINKING TO THE SITE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>You may link to the Site if you do not replicate the home page of the Site and subject to the following conditions:</span></p>
<p><span style={{fontWeight: "400"}}>(a) You do not remove, distort or otherwise alter the size or appearance of Our logos;</span></p>
<p><span style={{fontWeight: "400"}}>(b) You do not create a frame or any other browser or border environment around the Site;</span></p>
<p><span style={{fontWeight: "400"}}>(c) You do not in any way imply We are endorsing any products or services other than Our own products and services;</span></p>
<p><span style={{fontWeight: "400"}}>(d) You do not misrepresent Your relationship with Us nor present any other false information about Us;</span></p>
<p><span style={{fontWeight: "400"}}>(e) You do not otherwise use any of Our trademarks displayed on the Site without Our prior express written permission;</span></p>
<p><span style={{fontWeight: "400"}}>(f) Your website does not contain content that is distasteful, offensive or controversial towards Us or any third party, infringes any intellectual property rights or other rights of any other person or otherwise does not comply with all applicable laws and regulations.</span></p>
<p><span style={{fontWeight: "400"}}>We expressly reserve the right to revoke the right granted in this clause for breach of these Terms of Use and to take any action We deem appropriate. You shall fully indemnify Us for any loss or damage suffered by Us or any of Our group companies for breach of this clause.</span></p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>LIMITED LICENSE TO ACCESS AND USE THE SITE PROPRIETARY RIGHTS OF EaseOpt</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>You acknowledge and agree that the Site and any software used in connection with the Site contain proprietary and confidential information that is protected by applicable intellectual property and other laws.</span></p>
<p><span style={{fontWeight: "400"}}>All materials and contents of the Site, including without limitation, the website design text, software in object and source code (including applets), scripts, downloads, graphics, photos, interactive features and the like and their selection and arrangement, and the trademarks, trade names, trade dress, service marks and logos contained therein are owned by or licensed to </span><span style={{fontWeight: "400"}}>EaseOpt</span><span style={{fontWeight: "400"}}> and are subject to copyright and other intellectual property rights under all applicable laws.</span></p>
<p><span style={{fontWeight: "400"}}>All rights not expressly granted to You in these Terms of Use are reserved.</span></p>
<p><span style={{fontWeight: "400"}}>None of the above-mentioned materials may be modified, reproduced, duplicated, copied, sold, resold, transmitted, published, licensed, transferred, visited or otherwise exploited for any commercial purpose, stored in any other website, included in any public or private electronic retrieval system or service, redistributed or framed (or similar) without Our prior written permission.</span></p>
<p><span style={{fontWeight: "400"}}>You may not make any derivative use of this Site or its contents.</span></p>
<p><span style={{fontWeight: "400"}}>You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) belonging to Us without Our prior express written consent.</span></p>
<p><span style={{fontWeight: "400"}}>You may not use any meta tags or any other &ldquo;hidden text&rdquo; utilizing Our name or trademarks without Our prior express written consent.</span></p>
<p><span style={{fontWeight: "400"}}>We grant You a limited license to access and make personal use of the Site and not to download (other than page caching) or modify it, or any portion of it, except with Our prior express written consent.</span></p>
<p><span style={{fontWeight: "400"}}>You are permitted to print and download extracts from the Site for Your own personal use on the following basis:</span></p>
<p><span style={{fontWeight: "400"}}>(a) No documents or related graphics on the Site are modified in any way;</span></p>
<p><span style={{fontWeight: "400"}}>(b) No graphics on the Site are used separately from the corresponding text; and</span></p>
<p><span style={{fontWeight: "400"}}>(c)&nbsp; Our copyright and trademark notices and this permission notice appear in all copies.</span></p>
<p><span style={{fontWeight: "400"}}>For the avoidance of doubt, this clause does not permit any resale or commercial use of the Site or its contents; any derivative use of the Site or its contents or the use of robots, data mining or similar data collection and/or extraction tools is strictly prohibited.</span></p>
<p><span style={{fontWeight: "400"}}>Any unauthorized use terminates the permission or license granted by Us.</span></p>
<p>&nbsp;</p>
<ol start="8">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>SOFTWARE / DOCUMENT AVAILABLE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>Any software or document that is made available to download from this Site, whether for free or subject to payment of a license fee (&ldquo;Software&rdquo;) is the copyrighted work of EaseOpt, its affiliates, and/or their suppliers.</span></p>
<p><span style={{fontWeight: "400"}}>Your use of the Software is governed by the terms of the end user license agreement, if any, which accompanies, or is included with, the Software (&ldquo;License Agreement&rdquo;). You may not download, install or use any Software that is accompanied by or includes a License Agreement unless You first agree to the License Agreement terms through the double-click acceptance process provided on the Site.</span></p>
<p><span style={{fontWeight: "400"}}>For any Software or document which is not accompanied by a License Agreement, EaseOpt hereby grants You a personal, non-transferable license to use the Software for viewing and otherwise using this Site in accordance with these Terms of Use and for no other purpose.</span></p>
<p><span style={{fontWeight: "400"}}>Without limiting the foregoing, copying and reproduction of the Software to any other server or location for further reproduction or redistribution is expressly prohibited. The Software is warranted, if at all, only according to the terms of the License Agreement.</span></p>
<p><span style={{fontWeight: "400"}}>You acknowledge that the Software and any accompanying documentation and/or technical information are subject to applicable countries export control laws and regulations. You agree not to export or re-export the Software, directly or indirectly, to any countries that are subject to export restrictions.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="9">
<li><span style={{fontWeight: "400"}}> &nbsp; &nbsp; </span><strong>EXCLUSIONS AND LIMITATIONS OF LIABILITY</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>The Site and the data contained therein are provided &ldquo;as is&rdquo; and on an &ldquo;as available&rdquo; basis and without warranty, representation, condition or other term of any kind, either express or implied as to the operation of the Site or the information content, materials or products on the Site.</span></p>
<p><span style={{fontWeight: "400"}}>We exclude all representations, warranties, conditions and other terms which but for this clause might have effect in relation to the Site, including but not limited to:</span></p>
<p><span style={{fontWeight: "400"}}>(a) The implied warranties of merchantability, satisfactory quality, fitness for a particular purpose, use of reasonable care and skill or similar anywhere in the world;</span></p>
<p><span style={{fontWeight: "400"}}>(b) As to non-infringement of any third party&rsquo;s intellectual property rights of any content published or made available through the Site;</span></p>
<p><span style={{fontWeight: "400"}}>(c) As to the server(s) that make(s) the Service available being free of viruses or other disruptive code which may infect, harm or cause damage to Your computer equipment or any other property when You access, browse, download from or otherwise use the Site.</span></p>
<p><span style={{fontWeight: "400"}}>We do not guarantee that the Site will be error free and do not accept liability for any errors or omissions. You expressly agree that Your use of the Site is at Your sole risk.</span></p>
<p><span style={{fontWeight: "400"}}>We shall use reasonable endeavours to correct any errors or omissions as soon as reasonably practicable after being notified of them.</span></p>
<p><span style={{fontWeight: "400"}}>Any advice or recommendation given by Us or Our employees or agents to You which is not confirmed off-line in writing by Us, is followed or acted upon entirely at Your own risk, and accordingly We shall not be liable for any such advice or recommendation which is not so confirmed.</span></p>
<p><span style={{fontWeight: "400"}}>EaseOpt and any of officers, directors, employees, shareholders or agents of any of them exclude all liability and responsibility for any amount or kind of loss or damage that may result in any manner to You or a third party in connection with the Site and/or the Service or in connection with the use, inability to use, or the results of use of the Site and/or the Service, any third party websites linked to the Site, or the material on such websites, including, without limitation, any indirect, punitive or consequential loss or damages and any loss of income, profits, goodwill, data, contracts, use of money, loss or damages arising from or connected in any way to business interruption, due to viruses that may infect Your computer equipment, software, data or other property on account of Your access to, use of, or browsing the Site or Your downloading of any material from the Site or any third party websites linked to any of the Site and whether in tort (including without limitation negligence), contract or otherwise.</span></p>
<p><span style={{fontWeight: "400"}}>Nothing in these Terms of Use shall exclude or limit Our liability (i) for death or personal injury caused by Our negligence; (ii) for fraud; or (iii) for any liability which cannot be excluded or limited under applicable law.</span></p>
<p><span style={{fontWeight: "400"}}>If Your use of any material on the Site results in the need for servicing, repair or correction of equipment, software, or data, You assume all costs thereof.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="10">
<li><span style={{fontWeight: "400"}}> &nbsp; </span><strong>ELECTRONIC COMMUNICATIONS</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>When You visit the Site, buy Our products or send e-mails to Us, You are communicating with Us electronically, You consent to receive communications from Us electronically. We will communicate with You by e-mail or by posting notices on the Site. You agree that, to the extent permitted by applicable law, all agreements, notices, disclosures, and other communications that We provide to You electronically satisfy any legal requirement that such communications be in writing.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<ol start="11">
<li><span style={{fontWeight: "400"}}> &nbsp; </span><strong>MODIFICATION OF SERVICE</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>We reserve the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that We shall not be liable to You or to any third party for any modification, suspension, or discontinuance of the Service.</span></p>
<p><span style={{fontWeight: "400"}}>You agree to review the most current version of the Terms of Use.</span></p>
<p>&nbsp;</p>
<ol start="12">
<li><span style={{fontWeight: "400"}}> &nbsp; </span><strong>APPLICABLE LAW &ndash; JURISDICTION</strong></li>
</ol>
<p><span style={{fontWeight: "400"}}>The Terms of Use shall in all respects be governed and construed in accordance with the laws of India and any dispute arising out of or in connection with the use of the Site or the Services shall be brought before the exclusive jurisdiction of the competent Courts, Authority in India or State of Maharashtra or in Nashik.</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;</span></p>
<p><span style={{fontWeight: "400"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span><span style={{fontWeight: "400"}}> &nbsp; </span><strong><em>Team EaseOpt</em></strong></p>
    </div>
  )
}

export default Terms