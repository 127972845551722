import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
 import './sidebar.css';
import { AuthContext } from '../../data/utils/AuthContext';
import { Menu } from "antd";
import logo from '../header/Logo-minutes-removebg-preview.png';
import {
  FileTextOutlined,
  TeamOutlined,
  SnippetsOutlined,
  BankOutlined,
  IdcardOutlined,
  HomeOutlined,
  LogoutOutlined,
  BookOutlined,
  ControlOutlined
} from "@ant-design/icons";

const Sidebar = () => {
  const { authData } = useContext(AuthContext);
  const navigate = useNavigate();
var admin = sessionStorage.getItem("isAdmin");
var items;
const forAdmin = [
  {
    key: `userdashboard`,
    icon: <ControlOutlined style={{ fontSize: '20px'}} />,
    label: `User Dashboard`,
    condition: true,
    navigate: "/userdashboard",
  },
  {
    key: `subscription`,
    icon: <IdcardOutlined style={{ fontSize: '20px'}} />,
    label: `Payments`,
    condition: true,
    navigate: "/subscription",
  },
  {
    key: `client`,
    icon: <TeamOutlined style={{ fontSize: '20px'}} />,
    label: `My Clients`,
    condition: true,
    navigate: "/companies",
  },
  {
    key: `template`,
    icon: <SnippetsOutlined style={{ fontSize: '20px'}} />,
    label: `Agenda Templates`,
    condition: true,
    navigate: "/templates",
  },
  {
    key: `document`,
    icon: <FileTextOutlined style={{ fontSize: '20px'}} />,
    label: `Generate Minutes`,
    condition: true,
    navigate: "/document/new",
  },
  {
    key: `logout`,
    icon: <LogoutOutlined style={{ fontSize: '20px'}} />,
    label: `Logout`,
    condition: true,
    navigate: "/logout",
  },
];

const forUser = [
  {
    key: `userdashboard`,
    icon: <ControlOutlined style={{ fontSize: '20px'}} />,
    label: `User Dashboard`,
    condition: true,
    navigate: "/userdashboard",
  },
  {
    key: `subscription`,
    icon: <IdcardOutlined style={{ fontSize: '20px'}} />,
    label: `Payments`,
    condition: true,
    navigate: "/subscription",
  },
  {
    key: `client`,
    icon: <TeamOutlined style={{ fontSize: '20px'}} />,
    label: `My Clients`,
    condition: true,
    navigate: "/companies",
  },
  {
    key: `template`,
    icon: <SnippetsOutlined style={{ fontSize: '20px'}} />,
    label: `Agenda Templates`,
    condition: false,
    navigate: "/templates",
  },
  {
    key: `document`,
    icon: <FileTextOutlined style={{ fontSize: '20px'}} />,
    label: `Generate Minutes`,
    condition: true,
    navigate: "/document/new",
  },
  {
    key: `logout`,
    icon: <LogoutOutlined style={{ fontSize: '20px'}} />,
    label: `Logout`,
    condition: true,
    navigate: "/logout",
  },
]

if (admin == "true") {
  // setItems(forAdmin)
  console.log("admin");
  items = forAdmin
}
else{
  console.log("user");
  // setItems(forUser)
  items = forUser
}
  // const items = [
  //   // {
  //   //   key: `userguide`,
  //   //   icon: <BookOutlined style={{ fontSize: '20px'}}/>,
  //   //   label: `User Guide`,
  //   //   condition: true,
  //   //   navigate: "/userguide",
  //   // },
  //   {
  //     key: `userdashboard`,
  //     icon: <ControlOutlined style={{ fontSize: '20px'}} />,
  //     label: `User Dashboard`,
  //     condition: true,
  //     navigate: "/userdashboard",
  //   },
  //   {
  //     key: `subscription`,
  //     icon: <IdcardOutlined style={{ fontSize: '20px'}} />,
  //     label: `Payments`,
  //     condition: true,
  //     navigate: "/subscription",
  //   },
  //   {
  //     key: `client`,
  //     icon: <TeamOutlined style={{ fontSize: '20px'}} />,
  //     label: `My Clients`,
  //     condition: true,
  //     navigate: "/companies",
  //   },
  //   {
  //     key: `template`,
  //     icon: <SnippetsOutlined style={{ fontSize: '20px'}} />,
  //     label: `Agenda Templates`,
  //     condition: true,
  //     navigate: "/templates",
  //   },
  //   {
  //     key: `document`,
  //     icon: <FileTextOutlined style={{ fontSize: '20px'}} />,
  //     label: `Generate Minutes`,
  //     condition: true,
  //     navigate: "/document/new",
  //   },
  //   {
  //     key: `logout`,
  //     icon: <LogoutOutlined style={{ fontSize: '20px'}} />,
  //     label: `Logout`,
  //     condition: true,
  //     navigate: "/logout",
  //   },
  // ];


  var isAdmin = sessionStorage.getItem('isAdmin');
  const onMenuClick = ({ key }) => {
    // session.selectedKeys = [key];
    const to = items.find((x) => x.key === key)?.["navigate"] || "notfound";
    navigate(to);
  };
  
  return (
    <div>
      <div><a href="#default" class="logo"><img src={logo} alt="Logo" style={{width:'150px',height:'150px', objectFit:'contain'}} /></a></div>
      <Menu
            mode="inline"
            defaultSelectedKeys={["userdashboard"]}
            onClick={onMenuClick}
            items={items.filter((item) => item.condition)}
            style={{
              backgroundColor:'#E6FAFF'
            }}
          />
       
    </div>
    

        
  );
}

export default Sidebar;
