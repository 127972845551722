import { Button, Card, Space, Table, Tooltip, Typography } from "antd";
import Search from "antd/es/transfer/search";
import {
  FormOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTemplates,deleteTemplate } from "../../../data/templates";
const { Text } = Typography;
function SavedTemplates(props) {
    console.log(props.tableData);
  const [templates, setTemplates] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchAgendaText, setSearchAgendaText] = useState("");
  const [dataSource, setDataSource] = useState(templates);
  const [companies, setCompanies] = useState([]);
 const [mainTemplates,setMainTemplates] = useState([]);

    useEffect(() => {
        setTemplates(props.tableData.reverse());
    }, [props.tableData]);
    // useEffect(()=> {
    //   New = Orig.filter (id ...)
    //   SetDataSource(new)
    //   },[id])
    
    useEffect(() => {
      if (searchText.length !== 0 && templates) {
        const filteredData = dataSource.filter((entry) =>
          entry.name.includes(searchText)
        );
        setDataSource(filteredData);
      } else {
        setDataSource(templates);
      }
    }, [templates, searchText]);
  
    useEffect(() => {
      if (searchAgendaText.length !== 0 && templates) {
        const filteredData = dataSource.filter((entry) =>
          entry.agenda.includes(searchAgendaText)
        );
        setDataSource(filteredData);
      } else {
        setDataSource(templates);
      }
    }, [templates, searchAgendaText]);
  
    const FilterByNameInput = (
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <Text>Template Name</Text>
        <Search className="search"
          placeholder="Search Name"
          value={searchText}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchText(currValue);
          }}
        />
      </Space>
    );
  
    const FilterByAgendaInput = (
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <Text>Agenda</Text>
        <Search
          placeholder="Search Agenda"
          value={searchAgendaText}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchAgendaText(currValue);
          }}
        />
      </Space>
    );
  
    const columns = [
      {
        title: FilterByNameInput,
        dataIndex: "name",
        key: "name",
        width: "38%",
        render: (text) => (
          <Tooltip title={text}>
            <Text ellipsis={true}>{text}</Text>
          </Tooltip>
        ),
      },
      // {
      //   title: FilterByAgendaInput,
      //   dataIndex: "agenda",
      //   key: "agenda",
      //   width: "38%",
      //   render: (text) => (
      //     <Tooltip title={text}>
      //       <Text ellipsis={true}>{text}</Text>
      //     </Tooltip>
      //   ),
      // },
      {
        title: "Template type",
        dataIndex: "isSubTemplate",
        key: "isSubTemplate",
        width: "17%",
        render: (text) => (
          <Text ellipsis={true}>
            {text === "Y" ? "Sub Template" : "Main Template"}
          </Text>
        ),
      },
      {
        title: "Main Template",
        dataIndex: "mainTemplateName",
        key: "mainTemplateName",
        width: "17%",
        render: (text,index) => (
          <Tooltip title={text}>
            <Text ellipsis={true} >
             { 
                   [ text!= null ? (props.tableData.id == text ?"hello":text) : "-"]
                
              }
            </Text>
          </Tooltip>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "11%",
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <Link to={`/template/${record.id}`}>
                <EditOutlined />
              </Link>
            </Tooltip>
            {/* {record.createdBy.email === sessionStorage.email && ( */}
              <Tooltip title="Delete template">
                <DeleteOutlined
                  onClick={async () => {
                    await deleteTemplate(record.id);
                    setTemplates((await getTemplates()).reverse());
                  }}
                />
              </Tooltip>
            {/* )} */}
          </Space>
        ),
      },
    ];
  return (
    <div className='list-container'>

      <div className="header-container">
            <span className="heading">
                <h3>Templates</h3>
                </span>
            <div className="btn-container">
                <Link to={`/template/new`}><button className="btn"><i className="fa-solid fa-plus"></i>Add New Template</button></Link>
            </div>
        </div>
        <Table
        className="centerdiv"
        sticky={true}
        size="middle"
        bordered={true}
        // loading={templatesQuery.isFetching}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSize: 10,
        }}
      />
        {/* <div className="table-container">
          <table>
              <thead>
                <tr className="tble-hdr-row">
                    <th>
                      <div className="search">
                        <label htmlFor="search">Template Name</label>
                        <div className="input-icons">
                          <i className="fa-solid fa-magnifying-glass icon"></i>
                          <input type="text" id="search" className="search-input" placeholder="Search Name" />
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="search">
                        <label htmlFor="search">Agenda</label>
                        <div className="input-icons">
                          <i className="fa-solid fa-magnifying-glass icon"></i>
                          <input type="text" id="search" className="search-input" placeholder="Search Name" />
                        </div>
                      </div>
                    </th>
                    <th>Template type</th>
                    <th>Associated Company</th>
                    <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {props.tableData.map((template) => (
                <tr>
                     <td>{template.name}</td>     
                     <td>{template.agenda}</td>     
                     <td>{template.agenda}</td>     
                     <td>{template.agenda}</td>
                     <td>
                      <div className="actions">
                      <Link to={`/template/${template.id}`}>  <i className="fa-solid fa-pen-to-square icon-hover-edit" title="Edit Company"></i></Link>
                        <i className="fa-solid fa-trash icon-hover-trash" title="Delete Company" onClick={async () => {
                  await deleteTemplate(template.id);
                 // setTemplates((await getTemplates()).reverse());
                }}></i>
                      </div>
                    </td>     
                </tr>
              ))}
              </tbody>
          </table>
        </div> */}

    </div>
  )
}

export default SavedTemplates