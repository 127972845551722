import React,{useState} from 'react'

function Shareholders({index,id, shareHolders, onShareHolderUpdate, onShareHolderSubmit, onShareholderRemove}) {
  console.log(shareHolders);
  const [sname, setShareholder] = useState(shareHolders ? shareHolders.name : null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const newShareholder= { id, sname};
    if (shareHolders) {
      onShareHolderUpdate(id, newShareholder);
    } else {
      onShareHolderSubmit(newShareholder);
    }
    setShareholder("");
  };
  const handleRemove = () => {
    onShareholderRemove(index); // Call the parent component's remove function
  };
  return (
    <div>
    <form className="s-form single-box">
      <div className="input-container">
          <label>Shareholder Name</label>
          <input type="text" name="name" className='form-input' value={sname} onChange={(event) => setShareholder(event.target.value)}/>
      </div>
      <button className='remove-btn' onClick={handleRemove}>X</button>
    </form>
  </div>
  )
}

export default Shareholders