import React , {useState} from 'react'
import { Button,Input } from "antd";

function CompanyName({id,companyName,onCompanySubmit,onCompanyUpdate}) {
  const [name,setCompanyName] = useState(companyName ? companyName :"");
  const handleSubmit = (event) => {
    event.preventDefault();
    const newCompany = { id, name};
    if (companyName) {
      console.log(id,newCompany);
      onCompanyUpdate(id, newCompany);
    } else {
      onCompanySubmit(newCompany);
    }
    //setDirectorName("");
    //setDesignation("");
  };
  return (
    <div>
    <form className="c-form" onSubmit={handleSubmit}>
      <div className="input-container">
          <label>Company Name</label>
          <Input type="text" name="name" className='form-input' value={name} onChange={(event) => setCompanyName(event.target.value)}/>
      </div>
    </form>
  </div>
  )
}

export default CompanyName