import React from 'react'

function SelectedAgenda({agenda,editAgenda,removeAgenda}) {
  return (
    <ol className='agenda-items'>
         {agenda.map((option,index) => (
            option.name != null ? 
            <div className='list-cntr'  key={index}>
                <li key={option.name} value={option.name}>
                {option.name}
                </li><button onClick={() => removeAgenda(index)}>X</button>
            </div>
               : 
               <div className='list-cntr'  key={index}>
                  <li key={option.parameters[0].value} value={option.parameters[0].value}>{option.parameters[0].value} 
                  </li><button onClick={() => removeAgenda(index)}>X</button>
                </div> 
            ))}
    </ol>
  )
}

export default SelectedAgenda

{/* <button onClick={() => editAgenda(option)}>edit</button> */}