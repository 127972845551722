import React, { useState } from 'react';
import '../UserDashboard.css'

const Popup = ({ onClose, onSave }) => {
  const [originalPassword, setOriginalPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSave = () => {
    // Save the data to an array or perform any other actions
    onSave({
      originalPassword,
      newPassword
    });
  };

  return (
    <div className="popup">
        <div className="popup-header">
        <p className='pwd-popup-heading'>Change Password</p>
        <button onClick={onClose}>X</button>
        </div>
     <div className="password-popup">
     <div className="input-container">
      <label>
        Original Password:</label>
        <input
        className='form-input'
          type="password"
          value={originalPassword}
          onChange={(e) => setOriginalPassword(e.target.value)}
        />
      </div>
      <div className="input-container">
      <label>
        New Password:</label>
        <input
        className='form-input'
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className='btn-container'>
        <button onClick={handleSave}>Change Password</button>
        
      </div>
    </div>    
      
      <label htmlFor="msg" id="passwordMessage"></label>
      
    </div>
  );
};

export default Popup;
