import { Card } from "antd";
// import { ErrorBoundary } from "./ErrorBoundary";
import FileViewer from "react-file-viewer";
// import { FileViewer } from "./fileviewer";

function Preview ({ docs })  {
 var handleCopy = (event) => {
    event.preventDefault();
  }

 var handlePaste = (event) => {
    event.preventDefault();
  }
  return (
    <div  onCopy={handleCopy} onPaste={handlePaste}>
      
      {docs.length ? (
        
          // <div className="preview-div">
            <FileViewer className="preview-div"
              key={docs[0].uri}
              fileType={docs[0].fileType}
              filePath={docs[0].uri}
            />
          // </div>

         
        
      ) : (
        <p className="no-preview">No Preview Available</p>
      )}
    </div>
  );
};
export default Preview