import React from 'react'

function SessionExpiredPopup({onClose}) {
    return (
        <div className="popup session-popup">
          <div className="popup-content session-popup-content">
           
          <div className="popup-header">
            <p className='session-popup-header'>Session Expired</p>
            <button onClick={onClose}>X</button>
        </div>
            <p>Your Session is expired! Please login to continue.</p>
           
          </div>
        </div>
      );
}

export default SessionExpiredPopup