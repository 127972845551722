import { get, post, uploadWithForm } from "../context/rest";

const getTemplateParameterTypes = () => {
  return get(`documentTemplate/user/getAllParameterTypes`);
};

const getTemplates = (firmId, typeId = 1000) => {
  if (!firmId) return get(`documentTemplate/user/getAll?typeId=${typeId}`);
  return get(
    `documentTemplate/user/getAll?typeId=${typeId}&clientFirmId=${firmId}`
  );
};

const getTemplate = (id) => {
  console.log("id",id);
  if (!id) return;
  return getTemplates().then(
    (templates) => templates.filter((template) => template.id === id)?.[0]
  );
};

const deleteTemplate = (id) => {
  if (!id) return;
  return post(`documentTemplate/user/delete?documentTemplateId=${id}`);
};

const saveTemplate = (template) => {
  if (template.id) {
    console.log("inside save if");
    return uploadWithForm(`documentTemplate/user/update`, template);
  } else {
    return uploadWithForm(`documentTemplate/user/add`, template);
  }
};

export {
  getTemplateParameterTypes,
  getTemplates,
  getTemplate,
  saveTemplate,
  deleteTemplate,
};
