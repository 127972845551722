   const BASE_URL = "https://easeoptsolutions.in:8443/dev/minutes";
 //const BASE_URL = "http://easeoptsolutions.in:8081/dev/minutes";
// export const BASE_URL = "http://localhost:8443/dev/minutes";
//  export const BASE_URL = "http://localhost:8080/dev/minutes";

export const get = async (path, auth = true) => {
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "GET",
    headers: {
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  console.log("response in rest",res)
  if (res.ok) {
    try {
       return res.json();
    } catch(ex) {
      console.log(ex);
      
      return {};
    }
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // localStorage.removeItem('user');
      // setAuth(null);
      // history.push('/account/login');
      
      return {unauthorized:true}
  }
  }
  throw await res.json();
};

export const post = async (path, val = {}, auth = true) => {

  if (typeof val === "object") {
    val = JSON.stringify(val);
  }
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: val,
    headers: {
      "Content-Type": "application/json",
      "Content-Length": val.length,
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  if (res.ok) {
    try {
      if (
        (res.headers.get("Content-Length") &&
          res.headers.get("Content-Length") !== "0") ||
        res.headers.get("Content-Length") === null
      ) {
        return res.json();
      }
      return {};
    } catch {
      return {};
    }
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      return {unauthorized:true,isAnnualPaymentResponseSuccess:false}
  }
  if (res.status == 500) {
    console.log("res in rest: ",res);
    return {isRegistered:false,isAnnualPaymentResponseSuccess:false}
  }
  }
  throw await res.json();
};

export const download = async (
  path,
  val,
  filename = "minutes",
  auth = true
) => {
  if (typeof val === "object") {
    val = JSON.stringify(val);
  }
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: val,
    headers: {
      "Content-Type": "application/json",
      "Content-Length": val.length,
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  if (res.ok) {
  
    res.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      let filenameFromServer =  res.headers.get('content-disposition');
      console.log("filenameFromServer",filenameFromServer);
      if (filenameFromServer) {
        filename = filenameFromServer.split("=")[1];
        filename = filename.substring(0,filename.length-1);
       filename = filename.slice(1);

      } else {
        filename = `${filename}.docx`;
      }
      
      a.download = filename;
      a.click();
    });
    return;
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // localStorage.removeItem('user');
      // setAuth(null);
      // history.push('/account/login');
      return {unauthorized:true}
  }
  }
  throw await res.json();
};

export const preview = async (path, val, filename = "minutes", auth = true) => {
  if (typeof val === "object") {
    val = JSON.stringify(val);
  }
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: val,
    headers: {
      "Content-Type": "application/json",
      "Content-Length": val.length,
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  if (res.ok) {
    return res.blob().then((blob) => window.URL.createObjectURL(blob));
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // localStorage.removeItem('user');
      // setAuth(null);
      // history.push('/account/login');
     
      return {unauthorized:true}
  }
  }
  return undefined;
};

export const upload = async (path, name, val, auth = true) => {
  const formData = new FormData();
  formData.append(name, val);
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  if (res.ok) {
    try {
      if (
        (res.headers.get("Content-Length") &&
          res.headers.get("Content-Length") !== "0") ||
        res.headers.get("Content-Length") === null
      ) {
        return res.json();
      }
      return {};
    } catch {
      return {};
    }
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // localStorage.removeItem('user');
      // setAuth(null);
      // history.push('/account/login');
      
      return {unauthorized:true}
  }
  }
  throw await res.json();
};

// export const uploadWithForm = async(path, val, auth=true) =>{
//   fetch(`${BASE_URL}/${path}`, {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: auth
//         ? `Bearer ${sessionStorage.getItem("token")}`
//         : undefined,
//   },
//   body: JSON.stringify(val),
// })
//   .then(response => response.json())
//   .then(data => {
//     // Handle the response from the server
//     console.log(data);
//   })
//   .catch(error => {
//     // Handle any errors
//     console.error(error);
//   });
// }


export const uploadWithForm = async (path, val, auth = true) => {
  const res = await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: val,
    headers: {
      Authorization: auth
        ? `Bearer ${sessionStorage.getItem("token")}`
        : undefined,
    },
  });
  if (res.ok) {
    try {
      if (
        (res.headers.get("Content-Length") &&
          res.headers.get("Content-Length") !== "0") ||
        res.headers.get("Content-Length") === null
      ) {
        return res.json();
      }
      return {};
    } catch {
      return {};
    }
  }
  if (!res.ok) {
    if ([401, 403].includes(res.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // localStorage.removeItem('user');
      // setAuth(null);
      // history.push('/account/login');
      
      return {unauthorized:true}
  }
  }
  // throw await res.json();
};
