import React, {useState} from 'react'

function RelatedParty({index,id,relatedParty, onRelatedPartySubmit, onRelatedPartyUpdate,onRelatedPartyRemove}) {

  const [name,setRelatedParty] = useState(relatedParty ? relatedParty.name:null)
  const [natureOfRelation,setRelation] = useState(relatedParty ? relatedParty.natureOfRelation:null)

  const handleSubmit = (event) => {
    event.preventDefault();
    const newDirector = { id, name, natureOfRelation };
    if (relatedParty) {
      onRelatedPartyUpdate(id, newDirector);
    } else {
      onRelatedPartySubmit(newDirector);
    }
    //setDirectorName("");
    //setDesignation("");
  };
  const handleRemove = () => {
    onRelatedPartyRemove(index); // Call the parent component's remove function
  };
  return (
    <div>
    <form className="rp-form">
      <div className="input-container">
          <label>Related Party</label>
          <input type="text" name="name" className='form-input' value={name} onChange={(event) => setRelatedParty(event.target.value)}/>
      </div>
      <div className="input-container">
          <label>Relation</label>
          <input type="text" name="natureOfRelation" className='form-input' value={natureOfRelation} onChange={(event) => setRelation(event.target.value)}/>
      </div>
      <button className='remove-btn' onClick={handleRemove}>X</button>
    </form>
  </div>
  )
}

export default RelatedParty