import React , { useState , useEffect } from 'react';
import { Select } from "antd";
import { get } from '../../../context/rest';
function TemplateParameters({index,label, onFormUpdate , value}) {
 
    var [options,setOptions] = useState([]);
    const [val, setValue] = useState(value);
    const handleInputChange = (value) => {
      console.log("value",value);
        setValue(value)
        onFormUpdate(index,value);
      };
      function toTitleCase(str) {  
        return str.replace(/\w\S*/g,  
        function (txt) {  
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();  
        });  
    } 
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response =  await get(`documentTemplate/user/getAllParameterTypes`);
            var parameters = []
            // response.forEach(param => {
            //   var newVal = param.replaceAll("_"," ");
            //   var paramName = toTitleCase(newVal);
            //   var opt = {
            //     param:paramName
            //   }
            //   parameters.push(paramName)
            // });
            setOptions(response)
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);
  return (
    <div className='input-container'>
    <label htmlFor={index}>{toTitleCase(label)}</label>
    <Select  id={index} onChange={(value) => handleInputChange(value)}  value={val} required
    showSearch
    placeholder="Select a parameter"
    optionFilterProp="children"
    filterOption={(input, option) =>
       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {toTitleCase(option.replaceAll("_"," "))}
        </option>
      ))}
    </Select>
  </div>
  )
}

export default TemplateParameters