import React, { useState, useEffect } from 'react';
import { post, get } from '../../context/rest';
import { Link, useNavigate} from "react-router-dom";
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';
const PaymentStatus= () => {
    const urlParams = new URLSearchParams(window.location.search);
const  [response,setResponse] = useState(null)
    // Get the "id" parameter
    const tId = urlParams.get('transactionId');
   get(`payment/checkPaymentStatus?transactionId=${tId}`).then((res) => {
        setResponse(res);
    }
        
    )
    
    return (
      <div>
        {
          response == null ?  <div className='loader-overlay' id="spin">
          
             <Lottie animationData={button} className='loader'/>
        
          </div> :
            response ?  
            <div className='payment-div'>
              <div className='img-cntr'>
                <i class="fa-regular fa-circle-check success-icon icon"></i>
              </div>
              <div className='txt-cntr'>
                <p className='payment-text'>Payment Successful!</p>
              </div>
              <div  className='payment-link'><Link to="/subscription" className='link'>Go to Subscription</Link></div>
            </div> : 
            <div className='payment-div'>
              <div className='img-cntr'>
                <i class="fa-regular fa-circle-xmark failure-icon icon"></i>
              </div>
              <div className='txt-cntr'>
                <p className='payment-text'>Something went wrong! Please try after some time.</p>
              </div>
            <div className='payment-link'><Link to="/subscription" className='link'>Go to Subscription</Link></div>
        </div>
       
        }
         
       
            
      </div>
    );


    // useEffect(() => {
    //     var tId = sessionStorage.getItem("tId");
    //     console.log('tid', tId);
    //     console.log("hello");
    //     async function fetchData() {
    //       try {
    //         var res = await post(`payment/checkPaymentStatus?transactionId=${tId}`);
    //         console.log("response after redirection: ", res);
           
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
    
    //     fetchData();
    //   }, []); 

    //   return(
    //     <div>
    //         <h2>Payment Status</h2>
    //     </div>
    //   )
}

export default PaymentStatus