import React, { useRef, useState } from 'react'
import { Player } from 'video-react';
import './/userGuide.css';
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';
import 'video-react/dist/video-react.css'
const UserGuide = () => {
  // const videoUrl = './assets/newCompany.mp4';
  const [isOpen,setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const playerRef = useRef(null);
  const handleClick = event => {
   
    if (isOpen) {
      console.log("hello if");
      setIsOpen(false);
    }
    else{
      console.log("hello else");
      setIsOpen(true);
    }
  };
  

  return (
   
    <div>
      {/* <header className='home nav-main'>
        <div className="navbar">
        <div className="logo">
        <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3>EaseOpt</h3>
        </div>
        </div>
        <ul className='links'>
          <li><a href='/home'>Home</a></li>
          <li><a href='/home'>About Us</a></li>
          <li><a href='/userGuide'>User Guide</a></li>
          <li><a href='/home'>Why EaseOpt</a></li>
          <li><a href='/home'>EaseOpt Solutions</a></li>
          <li><a href='/query'>Query</a></li>
        </ul>
        <a href="" className='action-btn'>Login</a>
        <div className="toggle-btn">
             <i class="fa-solid fa-bars" onClick={handleClick}></i>
        </div>
        </div>
       <div className={`dropdown-menu${isOpen?' open':''}`}>
       <li><a href='/home'>Home</a></li>
          <li><a href='/home'>About Us</a></li>
          <li><a href='/userGuide'>User Guide</a></li>
          <li><a href='/home'>Why EaseOpt</a></li>
          <li><a href='/home'>EaseOpt Solutions</a></li>
          <li><a href='/query'>Query</a></li>
          <li><a href="" className='action-btn-dp'>Login</a></li>
       </div>
      </header> */}
      <div className="home user topnav" id="myTopnav">
       
      <div class="header-new header-home"> 
       <div className="nav-logo">
          {/* <div className="img-container">
          <Lottie animationData={button} />
          </div> */}
          <h3>EaseOpt</h3>
        </div>
    
    <div class="header-right">
              <a class="header-link" href='/home'>Home</a>
              <a class="header-link" href='/home'>About Us</a>
              <a class="header-link" href='/userGuide'>User Guide</a>
              <a class="header-link" href='/userguidevideo'>Video Guide</a>
              <a class="header-link" href='/home'>Why EaseOpt</a>
              <a class="header-link" href='/home'>EaseOpt Solutions</a> 
              <a class="header-link" href='/query'>Query</a>
              <a class="header-link" href='/login' className='test' id='login'>Login</a>
    </div>
  </div>
   <div className="user-text-content">
              <p className="home-header">
                User Guide
              </p>
            </div>
      </div>
     <section id="User-Guide-video">
        <div className='video'>
           <div className='demo-text'>
           
              <h3>Demo Video</h3>
              <p>A step by step guide to learn more about the software.</p>
      
            </div>
             <div className='main-video-container' style={{width:"85%", margin:"auto",padding:"50px",border: "1px solid #efefef",borderRadius:"5px",backgroundColor:"#f9f9f9"}}>
            <Player style={{boxShadow: "6px 6px 4px rgba(0, 0, 0, 0.25)",borderRadius:"5px"}}>
              <source src={require('.//assets//EaseOpt Demo.mp4')} />
            </Player>
            </div> 
           
        </div>
      </section>
      <section id='company-list'>
        <div className="section-container">
        <div className="right-section">
              <h3>Registered Companies</h3>
              <p>Your added companies will be listed here. To add a new company, select the 'Add New Company' option.</p>
          </div>
          <div className="left-section">
            <div className="img-container">
              <img src={require(".//assets//registeredCompanies.png")} alt="" />
            </div>
          </div>
          
        </div>
      </section>
      
       <section>
        <div className="section-container">
        <div className="right-section">
          <h3>Register Company</h3>
              <p>Kindly enter the Company Details below in the ‘Master File’ to generate Minutes of Meeting easily. One-time input in this file will provide drop-down options for future generation of minutes.. Please follow below steps:</p>
              <ol>
                <li>Add Name of the company.</li>
                <li>Click on <b>Add</b> button to add the respective fields. Fill the details.</li>
                <li>Kindly refer the video (on left side) for the idea of how to fill the data. (The values in form are just placeholders.)</li>
                <li>Click on submit button to save the data. Your company will be added and will be displayed in Registered companies.</li>
              </ol>

          </div>
          <div className="left-section">
          <div>
          <Player>
      <source src={require('.//assets//registerNewCompany.mp4')} />
    </Player>
             {/* <video controls="controls" autoplay="true" src={require('./assets/newCompany.mp4') } alt='Home page' /> */}
            </div>
          </div>
          
        </div>
       </section>
       {/* <section className='template'>
      
        <div className="section-container">
        <div className="right-section">
          <h3>Stored Templates</h3>
              <p>Your added templates will be listed here. To add a new template, select the 'Add New Template' option.</p>
          </div>
          <div className="left-section">
          <div className="img-container">
          <img src={require("./assets/storedTemplates.png")} alt="" />
            </div>
          </div>
         
        </div>
       </section> */}
       {/* <section>
        <div className="section-container">
        <div className="right-section">
          <h3>Add Template</h3>
              <p>Kindly enter the Template Details below to generate Minutes of Meeting easily. One-time input in this file will provide dropdown options for future generation of minutes.. Please follow below steps:</p>
              <ol>
                <li>Add Name of the template.</li>
                <li>Add the agenda of template</li>
                <li>Add the name of the company for which you are adding template(optional).</li>
                <li>If the template is sub template then click on is sub template switch otherwise leave it as it is.</li>
                <li>Upload the template file by using choose file option.</li>
                <li>Select the appropriate parameters from the dropdown. Refer the video(on left side).</li>
                <li>Click on submit button to save the data.Your template will be added and will be displayed in Stored Templates.</li>
              </ol>
          </div>
          <div className="left-section">
          <div>
          <Player className="video-player">
      <source src={require('./assets/newTemplate.mp4')} />
    </Player>
            </div>
          </div>
          
        </div>
       </section> */}
       <section>
        <div className="section-container">
        <div className="right-section col-2">
          <h3>Generate Minutes</h3>
              <p>To generate minutes please follow below steps:</p>
              <ol>
                <li>Select the name of company from drop-down.</li>
                <li>After company selection all Main templates will be loaded in Type of meeting field. Select the type of meeting from drop-down.</li>
                <li>All fields in that template will be loaded.</li>
                <li>Type the values and/or select the values from drop-down.</li>
                <li>Select the agenda either from already stored templates or you can add your own agenda.</li>
                <li>If you are using already stored templates then click on "Select from ready templates" button. Then one popup will open. Choose agenda from drop-down. Type and/or select value from drop-down.</li>
                <li>After successfully saving the data close the popup and you will get the preview of the document on right side.</li>
                <li>If you are using your own agenda then click on "Add your own agenda" button. Then one popup will open. Fill the values.</li>
                <li>After successfully saving the data close the popup and you will get the preview of the document on right side.</li>
                <li>After confirming that the preview is as expected then click on the "Generate" button to generate the document. Your document will be downloaded.</li>
              </ol>
          </div>
          <div className="left-section">
          <div>
          <Player>
      <source src={require('.//assets//generateMinutes.mp4')} />
    </Player>
      {/* <source src={require('.//assets//generateMinutes.mp4')} /> */}
    
    </div>
          </div>
         
        </div>
       </section>
       <section>
        <div className="section-container">
          <div className="left-section"></div>
          <div className="right-section"></div></div></section> 
    </div>
  )
}

export default UserGuide