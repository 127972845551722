import React , { useState , useEffect }  from 'react'
import { getTemplate , saveTemplate } from '../../data/templates';
import { useParams, useNavigate } from 'react-router-dom';
import { get , uploadWithForm, upload,post} from '../../context/rest';
import { UploadOutlined, ExclamationCircleFilled,CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from 'antd';
import SessionExpiredPopup from '../SessionExpiredPopup';
import TemplateParameters from './templateSupportingComponents/TemplateParameters';
import {getTemplates} from '../../data/templates';
import {
  Select
} from "antd";
function TemplateEdit() {
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };
  const { confirm } = Modal;
    const [template, setTemplate] = useState({});
    const [formData, setFormData] = useState([]);
    const {id} = useParams()
    const typeId = 1000;
    const [templateName, setTemplateName] = useState("")
    const [agenda, setAgenda] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [isSubTemplate, setIsSubTemplate] = useState("");
    const [parameters,setParameters] = useState([]);
    const [mainTemplates,setMainTemplates] = useState([]);
    const [mainTemplateId, setMainTemplateId] = useState("");
    const [isSubTemplateChecked, setIsSubTemplateChecked] = useState("N")
    const [mainTemplateName, setMainTemplateName] = useState("")
    var[file,setFile] = useState({});
    var [res,setRes] = useState([]);
    function handleNameChange(event){
      setTemplateName(event.target.value);
       template.name = event.target.value;
      }
      function handleAgendaChange(event){
        setAgenda(event.target.value)
       template.agenda = event.target.value;
      }
      function handleCompanyNameChange(event){
        setCompanyName(event.target.value);
          template.companyName = event.target.value;
      }
      function handleIsSubTemplate(event){
        setIsSubTemplate(event.target.value)
      }

    useEffect(() => {
        async function fetchData() {
          try {
         get(`documentTemplate/user/getAll?typeId=${typeId}`).then((res) => {
            res.forEach(element => {
                if (element.id == id) {
                  setTemplate(element);
                  setParameters(element.parameters);
                  setTemplateName(element.name);
                  setAgenda(element.agenda);
                  setIsSubTemplate(element.isSubTemplate);
                  if (element.isSubTemplate == "Y") {
                    console.log("in");
                    setIsSubTemplateChecked("Y");
                    setMainTemplateName(element.mainTemplateName);
                    document.getElementById('cb_id').checked = true;
                    getAllTemplates(sessionStorage.getItem('firmId'))
                  }
                }
            });
           
         })
           
          } catch (error) {
            console.error(error);
          }
        }
    
        fetchData();
      }, []);
      console.log("mtn",mainTemplateName);
      const handleFormUpdate = (fieldId, value) => {
        setFormData({ ...formData, [fieldId]: value }); // Update form data here
      };

      const handleFormSubmit = async (event) => {
        event.preventDefault();
       console.log(formData);
        var mappedparameters = Object.values(formData)
        var mappedKeys = Object.keys(formData)
          
        var unchangedParameters = [];
        var changedParameters = []
         parameters.map((param, id) => {
          mappedKeys.map((key,i) =>{
              if (parseInt(key) == id) {;
                param.type = mappedparameters[i]
                changedParameters.push(param)
              }
          })
        });
       
        parameters.map((param, id) => {
          if (!changedParameters.includes(param)) {
            unchangedParameters.push(param)
          }
        });
       var finalParams = changedParameters.concat(unchangedParameters);
       console.log("final params",finalParams);
        template.parameters = finalParams
        
     
      return confirm({
        title: "Do you Want to save these details?",
        icon: <ExclamationCircleFilled />,
        async onOk() {
          var res = await post(`documentTemplate/user/updateWithoutFile1`, template)
          if(res.unauthorized){
            setShowPopup(true)
          }
          navigate(-1);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };
      

    async function uploadTemplate(){
      var file = document.getElementById('template').files[0];
      var res = await  upload("documentTemplate/user/extractParameters", "templateFile", file);
        if(res.unauthorized){
          setShowPopup(true)
        }
        else{
          setFile(file);
          setRes(res);
        }
        
  }


  function handleMainTemplateChange(event) {
    setMainTemplateId(event.target.value);
    template.mainTemplateId = event.target.value;
  }
  async function handleIsSubTemplate(event){
    var firmId = sessionStorage.getItem("firmId");
    if (event.target.checked) {
      setIsSubTemplateChecked('Y');
      getAllTemplates(firmId);
    }
    else{
      setIsSubTemplateChecked('N');
    }
    setIsSubTemplate(event.target.value)
  }
    
  async function getAllTemplates(firmId){
    var res = await getTemplates(firmId);
    var mainTemplatesArray = []
    res.forEach(element => {
        if (element.isSubTemplate=='N') {
          mainTemplatesArray.push(element);
        }
    });
    setMainTemplates(mainTemplatesArray)
  }
  function handleChange(value) {
    console.log(`Selected: ${value}`);
    setMainTemplateId(value);
    template.mainTemplateId = value;
  }

  return (
    <div className='list-container'>
      <div className="form-container">
      <div className="input-container">
                        <label htmlFor="companyName">Template Name</label>
                        <input className="form-input" type="text" value={templateName}  name="templateName" id="templateName" onChange={(event) =>handleNameChange(event)} required/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="companyName">Agenda</label>
                        <input className="form-input" type="text" value={agenda}  name="agenda" id="agenda" required onChange={(event) =>handleAgendaChange(event)} />
                    </div>
                    <div className="input-container">
                         <label htmlFor="companyName">Company Name(optional)</label>
                        <input className="form-input" type="text"  name="directorName" id="companyName" onChange={(event) =>handleCompanyNameChange(event)} value={companyName}/>
                    </div>
                    <div className="input-container">
                         <label>Is it a sub-template?</label>
                         {/* <label class="switch">
                            <input type="checkbox" className="checkbox" onChange={(event) => handleIsSubTemplate(event)}/>
                            <span class="slider round"></span>
                        </label> */}
                         <input id="cb_id" class="toggle-round" type="checkbox" onChange={(event) => handleIsSubTemplate(event)}/>
                          <label for="cb_id"></label>
                    </div>
                    { isSubTemplateChecked=='Y' ? 
                    
                    <div className="input-container">
                    <label htmlFor="companyName">Select Main Template</label>
                    <Select defaultValue={mainTemplateName} onChange={handleChange} style={{ width: "100%" }}>
                    {mainTemplates.map((item,index) => (
                       <option
                           index ={index}
                           value = {item.id}
                           id={item.id}
                       > {item.name} </option> 
                           ))}
        </Select>
                    {/* <select className='form-input'  onChange={(event) =>handleMainTemplateChange(event)}  id="mainTemplate" defaultValue={mainTemplateName}>
                     
                    {mainTemplates.map((item,index) => (
                       <option
                           index ={index}
                           value = {item.id}
                           id={item.id}
                       > {item.name} </option> 
                           ))}
                    </select> */}
               </div> : " "
                  }
                    <div className="input-container">
                        <label htmlFor="">Template Document</label>
                            <input type="file" name="file" id="template" onChange={uploadTemplate}/>
                            <div>{console.log(res)}</div>
                            <div className="inner-form">{res.map((item,index) => (
                            <TemplateParameters
                                index ={index}
                                label = {item.parameterName}
                                onFormUpdate = {handleFormUpdate}
                                value = {item.value}
                            />
        ))}</div>
                    </div>
                    <div className="inner-form" id='original'>{parameters.map((item,index) => (
                            <TemplateParameters
                                index ={index}
                                label = {item.parameterName}
                                onFormUpdate = {handleFormUpdate}
                                value = {item.type}
                            />
        ))}</div>

                    <div>
                    <button type="submit" onClick={handleFormSubmit} className='company-submit'>Submit</button>
                    </div>
      </div>
          
        {showPopup &&
      <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>}
    </div>
  )
}

export default TemplateEdit