import React, { useState , useEffect } from 'react'
import { upload , get } from "../../context/rest";
import TemplateParameters from './templateSupportingComponents/TemplateParameters';
import { useParams } from 'react-router-dom';
import {getTemplates, saveTemplate} from '../../data/templates'
import { uploadWithForm } from '../../context/rest';
import { useNavigate} from "react-router-dom";
import { Modal } from 'antd';
import { UploadOutlined, ExclamationCircleFilled , CloseCircleOutlined} from "@ant-design/icons";
import { getCompanies } from "../../data/companies";
import SessionExpiredPopup from '../SessionExpiredPopup';
import {
  Select
} from "antd";
import './templateSupportingComponents/template.css'
function TemplateDetails() {
    var [res,setRes] = useState([]);
    var[file,setFile] = useState({});
    const [formData, setFormData] = useState([]);
    const { id } = useParams();
    const [templateName, setTemplateName] = useState("")
    const [agenda, setAgenda] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [isSubTemplate, setIsSubTemplate] = useState("")
    const [isSubTemplateChecked, setIsSubTemplateChecked] = useState("")
    const [mainTemplateId, setMainTemplateId] = useState("")
    const [companies,setCompanies] = useState([]);
    const [mainTemplates,setMainTemplates] = useState([]);

    const navigate = useNavigate()
    const [showPopup, setShowPopup] = useState(false);
      const closePopup = () => {
        navigate('/login');
        setShowPopup(false);
      };

    var firmId = sessionStorage.getItem('firmId')
    useEffect(() => {
      async function fetchData() {
        try {
         var result = await getCompanies(firmId);
         var activeCompanies = [];
         result.forEach(element => {
            if (element.isDeleted == 'N') {
              activeCompanies.push(element)
            }
         });
        setCompanies(activeCompanies);
          console.log(activeCompanies);
        
        } catch (error) {
          console.error(error);
        }
      }
  
      fetchData();
    }, []);

   

    const { confirm } = Modal;
    async function uploadTemplate(){
        var file = document.getElementById('template').files[0];
        var res = await  upload("documentTemplate/user/extractParameters", "templateFile", file);
       console.log("res in upload template");
          if(res.unauthorized){
            setShowPopup(true)
          }
          else{
            setFile(file);
            setRes(res);
          }
          
    }
    
    function handleNameChange(event){
      setTemplateName(event.target.value)
    }
    function handleAgendaChange(event){
      setAgenda(event.target.value)
    }
    function handleCompanyNameChange(event){
      console.log("c name",event.target.value);
        setCompanyName(event.target.value)
    }
    function handleMainTemplateChange(event) {
      setMainTemplateId(event.target.value)
    }
    async function handleIsSubTemplate(event){
      var firmId = sessionStorage.getItem("firmId");
      if (event.target.checked) {
        setIsSubTemplateChecked('Y');
        var res = await getTemplates(firmId);
        var mainTemplatesArray = []
        res.forEach(element => {
            if (element.isSubTemplate=='N') {
              mainTemplatesArray.push(element);
              
            }
        });
        setMainTemplates(mainTemplatesArray)
      }
      else{
        setIsSubTemplateChecked('N');
      }
      setIsSubTemplate(event.target.value)
    }
    const handleFormSubmit = async (event) => {
      event.preventDefault();
      var subTempValue;
      var mTemplateId;
        if (isSubTemplate) {
            subTempValue = 'Y'
            mTemplateId = mainTemplateId;
        }
        else{
            subTempValue = 'N'
        }
   
      var parameters = Object.values(formData)

      const finalParams = res.map((param, id) => {
        return {
          ...param,
          type: parameters[id],
        };
      });
      console.log(finalParams);
      var cFirmId = null;
      if (companyName != "") {
        cFirmId =companyName;
      }
      var firmId = sessionStorage.getItem('firmId')
    let  data = {
        mainTemplateId : mTemplateId,
        name:templateName,
        agenda:agenda,
        clientFirmId:cFirmId,
        isSubTemplate:subTempValue,
        parameters:finalParams,
        documentTypeId:1000,
        isDeleted:"N"
    }
    console.log(data);
    var temp = JSON.stringify(data)
    const formdata = new FormData();
    formdata.append("templateDocument", file);
    formdata.append("documentTemplate", temp);
    //  var response = await uploadWithForm(`documentTemplate/user/add`,formdata);
    //  console.log(response);
    
     return confirm({
      title: "Do you Want to save these details?",
      icon: <ExclamationCircleFilled />,
      async onOk() {
         var response = await uploadWithForm(`documentTemplate/user/add`,formdata);
         if (response.unauthorized) {
            setShowPopup(true);
         }
          console.log("form",response);
        navigate(-1);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

    
  const [mainTemplateName, setMainTemplateName] = useState("")
    const handleFormUpdate = (fieldId, value) => {
      setFormData({ ...formData, [fieldId]: value }); // Update form data here
    };

    function handleChange(value) {
      console.log(`Selected: ${value}`);
      setMainTemplateId(value);
    }

  return (
    <div className='list-container'>
      <div className="header-container">
        <span className="heading">
          <h3>Add Template</h3>
        </span>
      </div>
      <div className="form-container">
      <div className="input-container">
                        <label htmlFor="companyName">Template Name</label>
                        <input className="form-input" type="text"  name="templateName" id="templateName" onChange={(event) =>handleNameChange(event)} required/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="companyName">Agenda</label>
                        <input className="form-input" type="text"  name="agenda" id="agenda"  onChange={(event) =>handleAgendaChange(event)} required/>
                    </div>
                    <div className="input-container">
                         <label htmlFor="companyName">Company Name(optional)</label>
                         <select className='form-input' onChange={(event) =>handleCompanyNameChange(event)}  id="companyName">
                          <option value=""></option>
                         {companies.map((item,index) => (
                            <option
                                index ={index}
                                value = {item.id}
                            > {item.name} </option>
                                ))}
                         </select>
                    </div>
                    <div className="input-container">
                         <label>Is it a sub-template?</label>
                         {/* <label class="switch">
                            <input type="checkbox" className="checkbox" onChange={(event) => handleIsSubTemplate(event)}/>
                            <span class="slider round"></span>
                        </label> */}
                         <input id="cb_id" class="toggle-round" type="checkbox" onChange={(event) => handleIsSubTemplate(event)}/>
                          <label for="cb_id"></label>
                    </div>
                    {isSubTemplateChecked=='Y' ? 
                    
                    <div className="input-container">
                    <label htmlFor="companyName">Select Main Template</label>
                    <Select defaultValue={mainTemplateName} onChange={handleChange} style={{ width: "100%" }}>
                    {mainTemplates.map((item,index) => (
                       <option
                           index ={index}
                           value = {item.id}
                           id={item.id}
                       > {item.name} </option> 
                           ))}
        </Select>
                    {/* <select className='form-input' onChange={(event) =>handleMainTemplateChange(event)}  id="mainTemplate">
                     <option value=""></option>
                    {mainTemplates.map((item,index) => (
                       <option
                           index ={index}
                           value = {item.id}
                           id={item.id}
                       > {item.name} </option>
                           ))}
                    </select> */}
               </div> : " "
                  }
                    
        <div className="input-container">
                        <label htmlFor="">Template Document</label>
                            <input type="file" name="file" id="template" onChange={uploadTemplate}/>
                            <div>{console.log(res)}</div>
                            <div className="inner-form template-parameters">{res.map((item,index) => (
                            <TemplateParameters
                                index ={index}
                                label = {item.parameterName}
                                onFormUpdate = {handleFormUpdate}
                                value = {item.value}
                            />
        ))}</div>
                    </div>
                    <div>
                    <button type="submit" onClick={handleFormSubmit} className='company-submit'>Submit</button>
                    </div>
                   
      </div>
      {showPopup &&
      <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>}
    </div>
  )
}

export default TemplateDetails