import React from 'react'
import './Home.css'
import { useNavigate} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Lottie from 'lottie-react';
import stopWatch from './54348-stop-watch (1).json'
import watch from './61943-time.json'
// import aboutUs from './Ilustração Wamps Comunidade  2.json'
import meeting from './Comp 2.json'
import button from './h02YifhsUJ.json';
import conference from './76106-conference.json';
import group from './112062-team.json'
import twoPersonsAnimation from './121102-two-businessmen-work-in-the-office-collaboration-search-for-solutions.json'
import { Link } from "react-router-dom";
import Header from "../header/Header";
import logo from '../header/Logo-minutes-removebg-preview.png';
function Home() {
  AOS.init();
  const navigate = useNavigate();
  function navigation(){
      navigate('/login')
  }


 // Function to toggle the switch button
//  function toggleSwitch() {
//   var switchElement = document.getElementById("toggleSwitch");
//   switchElement.checked = !switchElement.checked;
// }

// // Set the time intervals (in milliseconds)
// var toggleOnInterval = 3000; // Toggle on after 3 seconds
// var toggleOffInterval = 3000; // Toggle off after 3 seconds

// // Function to toggle the switch button on and off
// function toggleOnOff() {
//   toggleSwitch(); // Toggle the switch button

//   // Determine the next action based on the current state
//   var switchElement = document.getElementById("toggleSwitch");
//   if (switchElement.checked) {
//     setTimeout(toggleOnOff, toggleOffInterval); // Schedule toggle off after toggleOffInterval
//   } else {
//     setTimeout(toggleOnOff, toggleOnInterval); // Schedule toggle on after toggleOnInterval
//   }
// }

// // Start the initial toggle after toggleOnInterval
// setTimeout(function() {
//   toggleOnOff();
// }, toggleOnInterval);
  return (
    <div className="main-container">
     
      <section className="home" data-aos="fade-in" data-aos-offset="400" data aos-duration="1000">
    
      <div class="header-new header-home">
      <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3>EaseOpt</h3>
        </div>
    {/* <a href="#default" class="logo"><img src={logo} alt="Logo" style={{width:'100px',height:'100px', objectFit:'contain'}} /></a> */}
    <div class="header-right">
              <a class="header-link" href='/home'>Home</a>
              <a class="header-link" href='/home#About'>About Us</a>
              <a class="header-link" href='/userGuide'>User Guide</a>
              <a class="header-link" href='/userguidevideo'>Video Guide</a>
              <a class="header-link" href='/home#Solution'>Why EaseOpt</a>
              <a class="header-link" href='/home#EaseOpt'>EaseOpt Solutions</a> 
              <a class="header-link" href='/query'>Query</a>
              <a class="header-link" href='/login' className='test' id='login'>Login</a>
    </div>
  </div>
   {/* <div className='nav-container'>
      <nav>
        <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3>EaseOpt</h3>
        </div>
          <ul>
            <li><a href='/home'>Home</a></li>
            <li><a href='#About'>About Us</a></li>
            <li><a href='/userGuide'>User Guide</a></li>
            <li><a href='#Solution'>Why EaseOpt</a></li>
            <li><a href='#EaseOpt'>EaseOpt Solutions</a></li>  
            <li><a href='/query'>Query</a></li>  
            <li className='login-button'><a href='/login' >Login</a></li>
          </ul>    
      </nav> 
   </div> */}
        <div className="home-container">
          <div className="home-text text">
            <div className="home-text-content">
              <p className="home-header">
                Easily generated fast and accurate compliance solutions
              </p>
              <p className="home-para">
                EaseOpt empowers professional individuals, teams & firms to
                obtain more strategic and sequential output. Engage for a
                striking experience within <b>MINUTES</b>
              </p>
             
            </div>
          </div>
        </div>
      </section>
   
      <section className="about" id="About">
        
     
        <div className="about-container" data-aos="fade-in" data-aos-offset="100" delay="800" data-aos-easing="ease-in-out">
          <div className="abt-left-container">
              {/* <div className='emptyDiv'></div>
              <div className="about-image">
                
              </div> */}
              <Lottie animationData={meeting}/>
          </div>
         
          <div className="about-text"  >
            <div className="about-text-content">
              <p className="about-header">
               <b>Our mission</b> is to initiate a seamless working system through an
                easy-to-use tool.
              </p>
              <div className="about-our-story">
                <div className="vertical-line"></div>
                <p>Our Story</p>
              </div>
              <p className="about-para">
                Until now, drafting accurate meeting-based compliances and
                documents required manually monitored precision, speed &
                efficiency. Hereon, it will only require clicks to generate the
                same data so that you can focus on other essential elements of
                meetings.
              </p>
              <div className="about-our-story">
                <div className="vertical-line"></div>
                <p className="message"> We want to simplify this.</p>
              </div>
              <p className="about-para">
                The traditional approach for generation of minutes often 
                <ul>
                  <li>Demands undivided human attention</li>
                  <li>Consumes precious time</li>
                  <li>Gets affected from a backlog of other tasks</li>
                </ul>
              </p>
              <p className='about-para'>
              Consequently, these crucial tasks may be overlooked due to a lack of designated deadlines. Such systems keep us occupied without actually enhancing productivity. This is why we wanted to streamline the process in the first place in order to make it more efficient
              </p>
              <p className='about-para'>
              And we believe there are ways to do the same. At <b>EaseOpt Solutions</b>, we are committed to providing professionals like you with the tools who need to work smarter, not harder. 

              </p>
              <p className='about-para'>
              We help you attain solutions that are comprehensive & easily achievable, within clicks.

              </p>
              
              <button className="about-button" onClick={navigation}>Get Started</button>
              <a href="/terms" className='about-para terms-text' target='_blank'>Terms and Conditions to use EaseOpt Solutions.</a>
            </div>
          </div>
        </div>
      </section>
      <section className='crisp-object'  data-aos="fade-in" data-aos-offset="400" delay="1000" data-aos-easing="ease-in-out" id="Solution">
      <p className="easeopt-header crisp-header">How EaseOpt Uniquely Helps You Opt Easy Solutions
</p>
        <div className='card-container'>
          <div className="card">
          <span class="material-symbols-outlined">
bookmark
</span>
            <div className="card-heading"><h3 className='heading'>SAVE</h3></div>
            <div className="card-text"><p className='para'>At least 50% of your time compared to the traditional process of generation of Minutes.
</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
tips_and_updates
</span>
            <div className="card-heading"><h3 className='heading'>DISCOVER</h3></div>
            <div className="card-text"><p className='para'>150+ ready templates to choose one from, for easy Minutes’ generation.</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
hard_drive_2
</span>
            <div className="card-heading"><h3 className='heading'>STORE</h3></div>
            <div className="card-text"><p className='para'> Company details in ‘Master File’ only once.</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
draft_orders
</span>
            <div className="card-heading"><h3 className='heading'>COMPILE</h3></div>
            <div className="card-text"><p className='para'>Your pre-formatted data even if not recorded from EaseOpt’s templates, for a structured generation of Minutes.
</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
preview
</span>
            <div className="card-heading"><h3 className='heading'>PREVIEW</h3></div>
            <div className="card-text"><p className='para'>The document before its generation & avoid cross-checking post download.
</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
point_scan
</span>
            <div className="card-heading"><h3 className='heading'>LOCATE</h3></div>
            <div className="card-text"><p className='para'>Your previous meeting details and archives.</p></div>
          </div>
          <div className="card">
          <span class="material-symbols-outlined">
timer
</span>
            <div className="card-heading"><h3 className='heading'>OPTIMIZE</h3></div>
            <div className="card-text"><p className='para'>Digital solutions for accurate & faster results.
</p></div>
          </div>
        </div>
      </section>
      <section className="easeopt" data-aos="fade-in" data-aos-offset="500" delay="1000" data-aos-easing="ease-in-out" id="EaseOpt">
        <div className="easeopt-container">
        <Lottie animationData={twoPersonsAnimation} className='ease-opt-animation' />
          <div className="easeopt-text">
            <div className="easeopt-text-content">
              <p className="easeopt-header">EaseOpt Minutes</p>
              <p className="easeopt-para">
              Generating accurate and timely minutes of meetings during compliances is one of the essential tasks for professionals. We know that preparing these documents can often be a tedious and time-consuming task, but neglecting them can have serious consequences.
              </p>
              <p className="easeopt-para">
              As Company Secretaries by profession, we realized it’s significance and hence developed a solution that streamlines the process and eliminates the risk of clerical errors.
              </p>
              <p className="easeopt-para">
              The software’s brilliance lies in its minimalistic approach as it only requests necessary details from you and then delivers tailor-made solutions that perfectly fits your needs.

              </p>
              <div className="about-our-story">
                <div className="vertical-line"></div>
                <p>EaseOpt Solutions.</p>
              </div>
              <p className="easeopt-para">
              Our goal is to empower professionals by providing them with an easy-to-use tool that saves time and increases their potential for additional income. 

              </p>
              <p className="easeopt-para">
              We are dedicated to helping professionals overcome the challenges they face when it comes to generating minutes. With our innovative solution, preparing these documents will no longer be a challenge. Our commitment to excellence ensures that every use can rely on our product to be accurate, reliable, and efficient.

              </p>
             
              <button className="easeopt-button" onClick={navigation}>Get Started</button>
              <a href="/terms" className='terms-text' target='_blank'>Terms and Conditions to use EaseOpt Solutions.</a>
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="footer-logo">
        <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3 style={{color:"#026882",marginTop:"10px"}}>EaseOpt</h3>
        </div>
        </div>
        <div className="footer-nav-links">
        <ul>
            <li><a href='/home'>Home</a></li>
            <li><a href='#About'>About Us</a></li>
            <li><a href='/userGuide'>User Guide</a></li>
            <li><a href='#Solution'>Why EaseOpt</a></li>
            <li><a href='#EaseOpt'>EaseOpt Solutions</a></li>  
            <li><a href='/query'>Query</a></li>  
          </ul>
        </div> 
            <div className='footer-details'>
                <h3>EaseOpt Solutions Private Limited</h3>
                <p>CIN: U47413MH2023PTC398235</p>
                <p>Email id: contact@easeoptsolutions.in</p>
                <p>Registered office: Flat No. 101, Green Woods Appt.,<br /> Plot No. 733/2B/2/9 B/h Idea Showroom, Samartha Nagar,<br /> Nashik - 422005 Maharashtra, India.</p>
            </div>
      </section>
    </div>
  )
}

export default Home