import React,{useState} from 'react'
import { MultiSelect } from 'antd'
import { Option } from 'antd/es/mentions'
function User({ onRemove, onValueChange  }) {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    onValueChange({ ...values, [name]: value });
  };

  return (
    <div className="user">
          <div className='user-form'>
      <div className="input-container">
        <label htmlFor="">Name</label>
      <input type="text" name="name" value={values.name} onChange={handleChange} className='form-input u-input'/>
      </div>
      <div className="input-container">
      <label htmlFor="">Email</label>
      <input type="text" name="email" value={values.email} onChange={handleChange} className='form-input u-input'/>
      </div>
      {/* <div className="input-container">
      <label htmlFor="">Password</label>
      <input type="password" name="password" value={values.password} onChange={handleChange} className='form-input u-input p-input'/>
      </div> */}
      <div className="input-container">
      <label htmlFor="">Contact</label>
      <input type="text" name="contact" value={values.contact} onChange={handleChange} className='form-input u-input'/>
      </div>
      {/* <div className="input-container">
      <label htmlFor="">Role</label>
      <select type="text" name="role" value={values.role} onChange={handleChange} className='form-input u-input s-input'>
        <option value="ADMIN">Admin</option>
        <option value="USER">user</option>
      </select>
      </div> */}
      <div>
      <button onClick={onRemove}>X</button>
      </div>
      
    </div>
    <div>
       
    </div>
    </div>
    
  );
};


export default User
