import React, { useState, useEffect } from "react";
import { getTemplates } from "../../data/templates";
import SavedTemplates from "./templateSupportingComponents/SavedTemplates";
import SessionExpiredPopup from "../SessionExpiredPopup";
import { useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';



function TemplateList() {
   // var id = sessionStorage.getItem("firmId")
  var typeId=1000;
  var firmId = sessionStorage.getItem('firmId')
  var path = `documentTemplate/user/getAll?typeId=${typeId}&clientFirmId=${firmId}`;
  const [templates, setTemplates] = useState([]);
  const[isDataLoaded,setIsDataLoaded] = useState(true);
  //setUsers(result)
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };
  useEffect(() => {
    async function fetchData() {
      try {
       var result = await getTemplates(firmId);
       setIsDataLoaded(false);
       document.getElementById('spin').classList.remove('loader-overlay')
       if (result.unauthorized) {
          setShowPopup(true);
       }
       else{
        var activeTemplates = [];
        console.log("all templates",result);
        result.forEach(element => {
           if (element.isDeleted == 'N') {
             activeTemplates.push(element)
           }
        });
        setTemplates(activeTemplates)
         console.log("active templates",activeTemplates);
       }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    
    <div>
     <div className='loader-overlay' id="spin">
                  {isDataLoaded && (
                     <Lottie animationData={button} className='loader'/>
                )}
                  </div>
        <SavedTemplates tableData={templates}/>
        {showPopup &&
      <div className='popup-overlay'>
            {showPopup && <SessionExpiredPopup onClose={closePopup} />}
          </div>}
    </div>
  )
}
export default TemplateList