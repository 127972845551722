import React, {useState,useEffect} from "react";
import "./companySupport.css"
function Address({index, id, addresses, onAddressSubmit, onAddressUpdate,onAddressRemove   }) {
  const [isRegisteredAddress, setIsRegisteredAddress] = useState(addresses ? addresses.isRegisteredAddress : null);
  const [oaddress, setAddress] = useState(addresses ? addresses.address : null);
  // useEffect(() => {
  //   console.log(address);
  //   setIsRegisteredAddress(address?.isRegisteredAddress || "");
  //   setAddress(address?.address || ""); 
  // }, [address]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newAddress = { id, isRegisteredAddress, oaddress };
    if (addresses) {
      onAddressUpdate(id, newAddress);
    } else {
      onAddressSubmit(newAddress);
    }
    setIsRegisteredAddress("");
    setAddress("");
  };
  const handleRemove = () => {
    onAddressRemove(index); // Call the parent component's remove function
  };
  
  return (
    <form className="a-form" onSubmit={handleSubmit}>
      <div className="form">
      <div className="input-container">
            <label htmlFor="">Address Type</label>
            <select name="isRegisteredAddress" id="" className="form-input" defaultValue={isRegisteredAddress} onChange={(event) => setIsRegisteredAddress(event.target.value)} >
                <option value="Select Address Type" disabled>Select Address Type</option>
                <option value="Y">Registered office address</option>
                <option value="N">Other Address</option>
            </select>
        </div>
        <div className="input-container">
            <label htmlFor="">Address</label>
            <textarea rows={3} cols={30} name="address" className="form-input" value={oaddress} onChange={(event) => setAddress(event.target.value)}/>
        </div>
        <button className="remove-btn" onClick={handleRemove}>X</button>
      </div>
    </form>
  );
}

export default Address