import React, {useState} from 'react'

function StatutoryAuditors({index,id, statutoryAuditors, onStatutoryAuditorsUpdate, onStatutoryAuditorsSubmit,onStatutoryAuditorsRemove}) {
  const [sname, setStatutoryAuditor] = useState(statutoryAuditors ? statutoryAuditors : null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const newShareholder= { id, sname};
    if (statutoryAuditors) {
      onStatutoryAuditorsUpdate(id, newShareholder);
    } else {
      onStatutoryAuditorsSubmit(newShareholder);
    }
    setStatutoryAuditor("");
  };
  const handleRemove = () => {
    onStatutoryAuditorsRemove(index); // Call the parent component's remove function
  };
  return (
    <div>
    <form className="sa-form single-box">
      <div className="input-container">
          <label>Statutory Auditor Name</label>
          <input type="text" name="name" className='form-input single-input' value={sname} onChange={(event) => setStatutoryAuditor(event.target.value)}/>
      </div>
      <button className='remove-btn' onClick={handleRemove}>X</button>
    </form>
  </div>
  )
}

export default StatutoryAuditors