import { get, post } from "../context/rest";

const getAllowedValuesOfDesignations = () => {
  return [
    "Director",
    "Additional Director",
    "Managing Director",
    "Alternate Director",
    "Nominee Director",
    "Whole-time Director",
    "Independent Director",
    "Manager",
    "Chief Executive Officer",
    "Chief Financial Officer",
    "Company Secretary",
    "Invitee",
    "Authorized Representative",
    "Committee Member",
  ];
};

const getCompanies = async (firmId) => {
  if (!firmId) return;
  var companies = await get(`clientFirm/user/getAllWithActiveSubscription?firmId=${firmId}`);
  console.log("companies",companies);
  if (companies.unauthorized) {
    return companies
  }
  else{
    companies = companies.filter((c) => c.isDeleted === "N")
  }
  
 
  companies.forEach((company) => {
    Object.keys(company).forEach((key) => {
      if (Array.isArray(company[key])) {
        company[key] = company[key].filter((ad) => ad["isDeleted"] === "N");
      }
    });
  });
  return companies;
};

const getAllCompanies = async (firmId) => {
  if (!firmId) return;
  var companies = await get(`clientFirm/user/getAll?firmId=${firmId}`);
  if (companies.unauthorized) {
    return companies
  }
  else{
    companies = companies.filter((c) => c.isDeleted === "N")
  }
  
 
  companies.forEach((company) => {
    Object.keys(company).forEach((key) => {
      if (Array.isArray(company[key])) {
        company[key] = company[key].filter((ad) => ad["isDeleted"] === "N");
      }
    });
  });
  return companies;
}; 

const getCompany = (id) => {
  if (!id) return;
  return get(`clientFirm/user/get?clientFirmId=${id}`).then((company) => {
    if(company.unauthorized){
      return company
    } 
    Object.keys(company).forEach((key) => {
      if (Array.isArray(company[key])) {
        company[key] = company[key].filter((ad) => ad["isDeleted"] === "N");
      }
    });
   // return company;
  });
};

const deleteCompany = (company) => {
  if (!company) return;
  return post(`clientFirm/user/delete`, company);
};

const saveCompany = async (company) => {
  if (company.id) {
    await post(`clientFirm/user/update`, JSON.stringify(company));
  } else {
    return post(`clientFirm/user/add`, JSON.stringify(company));
  }
};

export {
  getCompanies,
  getAllCompanies,
  getCompany,
  getAllowedValuesOfDesignations,
  saveCompany,
  deleteCompany,
};
