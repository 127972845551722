import React from 'react'
import {
  Select
} from "antd";
function Template({options , onFormUpdate}) {
    var index = 0
    const handleInputChange = (value) => {
       
         // setValue(event.target.value)
        //  console.log(event.target.value);
          onFormUpdate(index,value);
        };
  return (
    <div>
         <div className='input-container'>
        <label htmlFor="">Type of Meeting</label>
        <Select  id={index} onChange={(value) => handleInputChange(value)}required
    showSearch
    placeholder="Select Main Template"
    optionFilterProp="children"
    filterOption={(input, option) =>
       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
      {options.map((option, index) => (
        <option key={index} value={option.name}>
          {option.name}
        </option>
      ))}
    </Select>
       
    </div>
    </div>
  )
}

export default Template