import React , { useState , useEffect } from 'react';
import { Link, json } from "react-router-dom";
import { deleteCompany, getCompanies } from '../../../data/companies';
import { Button, Card, Tooltip, Space, Table, Typography, Input } from "antd";
import Search from "antd/es/transfer/search";
import { FormOutlined, EditOutlined, DeleteOutlined, DownloadOutlined,FieldTimeOutlined } from "@ant-design/icons";
const { Text } = Typography;

function RegisteredCompanies(props) {

    console.log("companies",props.tableData);
    const [companies, setCompanies] = useState();
    const [searchText, setSearchText] = useState("");
    const [dataSource, setDataSource] = useState(companies);
    useEffect(() => {
      setCompanies(props.tableData);
      if (searchText.length !== 0 && companies) {
        const filteredData = companies.filter((entry) =>
          entry.name.includes(searchText)
        );
        setDataSource(filteredData);
      } else {
        setDataSource(props.tableData);
      }
    }, [props.tableData, searchText]);
  
    const FilterByNameInput = (
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <Text>Name</Text>
        <Search
          placeholder="Search Name"
          value={searchText}
          onChange={(e) => {
            const currValue = e.target.value;
            setSearchText(currValue);
          }}
        />
      </Space>
    );
  
    const columns = [
      {
        title: FilterByNameInput,
        dataIndex: "name",
        key: "name",
        // sorter: (a, b) => a.name > b.name,
        render: (text) => (
          <Tooltip title={text}>
            <Text ellipsis={true}>{text}</Text>
          </Tooltip>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "20%",
        render: (_, record) => (
          <Space size="middle">
            {/* <Tooltip title="Create minutes of meeting">
              <Link to={`/document/${record.id}`}>
                <FormOutlined />
              </Link>
            </Tooltip> */}
            <Tooltip title="Edit">
              <Link to={`/company/${record.id}`}>
                <EditOutlined  className='icon'/>
              </Link>
            </Tooltip>
            <Tooltip title="Delete company">
              <DeleteOutlined
                onClick={async () => {
                  await deleteCompany(record.id);
                  setCompanies(await getCompanies(sessionStorage.getItem("firmId")));
                }}
              />
            </Tooltip>
            <Tooltip title="Generate Minutes">
              <Link to={`/document/new/${record.name}`}>
              <FieldTimeOutlined />
              </Link>
            </Tooltip>
          </Space>
        ),
      },
    ];
  return (
    <div className='list-container'>

      <div className="header-container">
        
            <span className="heading">
                <h3>Registered Companies</h3>
                </span>
            <div className="btn-container">
              {/* <button className="btn"><i className="fa-solid fa-hourglass-half"></i> Generate Minutes</button> */}
                <Link to={`/company/new`}><button className="btn"><i className="fa-solid fa-plus"></i>Add New Company</button></Link>
            </div>
        </div>
        
        <Space style={{ display: "flex", justifyContent: "space-between" }}></Space>

      <Table
        className="centerdiv"
        sticky={true}
        size="middle"
        bordered={true}
        dataSource={dataSource}
        columns={columns}
        // loading={}
        pagination={{
          pageSize: 10,
        }}
      />
    </div>
  )
}

export default RegisteredCompanies