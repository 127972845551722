import React from 'react'
import Login from './Login'
import { observer } from 'mobx-react';
const Logout = () => {

  // const { authData, setStore, unsetStore } = authStore;
  // const { name, email, firm, roles, isAuthenticated } = authData.get();
  // unsetStore();
  sessionStorage.removeItem("tId")
  return (
    <Login></Login>
  )
}

export default Logout