import React, { useState } from 'react'
import { useParams , useNavigate } from 'react-router-dom';
import { post , get } from '../../context/rest';
import MessagePopup from './MessagePopup'
import { LoadingOutlined } from '@ant-design/icons';
import './updatePassword.css';
import { Spin } from 'antd';
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';

import  Header  from "../header/Header";

const ForgetPassword = () => {
const [userEmail,setEmail] = useState("");
const [showPopup, setShowPopup] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const navigate = useNavigate();
    async function sendMail() {
     
     var forgetPassword = `appUser/userAction/forgotPassword?userEmail=${userEmail}`;
      setIsLoading(true);
     post(forgetPassword).then((response) => {
      setIsLoading(false)   // Hide loading screen 
      if (Object.keys(response).length === 0 && response.constructor === Object) {
        setShowPopup(true)
      }
  });
      
    //  var res = await post(forgetPassword);
    //  console.log(res);
     
      // if (res.isRegistered !== undefined || res.unauthorized !== undefined) {
      //   setShowPopup(true)
      // }
   //sessionStorage.setItem("userId",formData.userName);
    //  navigate('/login')
    }
    const closePopup = () => {
      navigate('/login');
      setShowPopup(false);
    };


    return (
        <div>
          <Header />
         
                  {isLoading && ( <div className='loader-overlay' id="spin">
                     <Lottie animationData={button} className='loader'/>
                     </div>
                )}
                  
          <div className="pwd-form-container">
            <h3>Forgot Password</h3>
            <div className="msg">
              <p className='err-msg'></p>
              <p className='success-msg'></p>
            </div>
            <div className="input-container">
              <label htmlFor="">Username</label>
              <input type="text" name="password" id="pwd" onChange={(event) => setEmail(event.target.value)} className='form-input'/>
            </div>
            <button type="button" onClick={sendMail} className='form-input sub-button'>Verify Email</button>
          </div>
          {showPopup && 
        <div className='popup-overlay'>
            {showPopup && <MessagePopup onClose={closePopup} email={userEmail} />}
          </div>
          }
          
        </div>
      )
}

export default ForgetPassword