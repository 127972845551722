import React, { useState, useEffect } from "react";
import { Link, useNavigate} from "react-router-dom";
import { post } from '../../context/rest';
import {store, useGlobalState} from 'state-pool';
import Lottie from 'lottie-react';
import contactUs from './81967-contact-us.json'
import './query.css'
import button from '../home/h02YifhsUJ.json';
import Header from "../header/Header";
import { UploadOutlined, ExclamationCircleFilled , CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from 'antd';
//store.setState("session", 0);
function Query() {

  const [name,setName] = useState(); 
  const [contact,setContact] = useState(); 
  const [email,setEmail] = useState(); 
  const [occupation,setOccupation] = useState(); 
  const [query,setQuery] = useState(); 
  const { confirm } = Modal;
function sendEmail(){
    // console.log("hello");
    // console.log("name",name);
    // console.log("contact",contact);
    // console.log("email",email);
    // console.log("occupation",occupation);
    // console.log("query",query);
    var formData = {
      userName:name,
      oneLiner:"subject",
      description: query,
      contact:contact,
      occupation:occupation,
      email: email
    }
    var response = post(`queries/post`,formData);
		 console.log("response",response);
    return confirm({
      title: "Query submitted successfully!",
      icon: <ExclamationCircleFilled />,
      onOk() {
        
    // if(response.unauthorized){
    //   setShowPopup(true)
    // }
        window.location.reload()
      },
      onCancel() {
        console.log("Cancel");
      },
    });
}
  return (
    <div className="outer">
      <Header />
      
      
          <div className="wrapper">
      <div className="index-overlap-group2">
        <Lottie animationData={contactUs} className="animation"/>
            {/* <p className="index-text-wrapper">Any appropriate illustration or image can go here</p> */}
          </div>
          <div className="index-overlap-group3">
          <h2 className="index-h-1">Contact Us</h2>
            <div className="query-right-cntr">
            
            <div className="input-container">
              <label htmlFor="">Full Name</label>
              <input type='text' className="form-input input" name="name" onChange={(event) => {setName(event.target.value)}} />
             
            </div>
            <div className="input-container">
              <label htmlFor="">Email</label>
              <input type='text' className="form-input input" name="email" onChange={(event) => {setEmail(event.target.value)}} />
             
            </div>
            <div className="input-container">
              <label htmlFor="">Contact</label>
              <input type='text' className="form-input input" name="contact" onChange={(event) => {setContact(event.target.value)}} />
            </div>
            <div className="input-container">
              <label htmlFor="">Occupation</label>
              <input type='text' className="form-input input" name="occupation" onChange={(event) => {setOccupation(event.target.value)}} />
            </div>
            <div className="input-container">
              <label htmlFor="">Query</label>
              <textarea  className="form-input input" name="occupation" onChange={(event) => {setQuery(event.target.value)}} />
            </div>
            <div className="query-index-large-button">
              <div className="index-overlap-group">
                <div className="btn"><button onClick={sendEmail}>Submit</button></div>
              </div>
            </div>
            </div>
          </div>
    </div>
    </div>
    
  )
}

export default Query