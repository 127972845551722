// About.js
import { Layout } from "antd";
import { useEffect,useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import  SessionContext  from "../session";
import { AuthContext } from '../data/utils/AuthContext';
const { Content, Sider } = Layout;


function ProtectedRoute(props) {
  console.log("role",props.role);
 // const { session } = useContext(SessionContext);

 const { authData } = useContext(AuthContext);
console.log("authData",authData);
  const [authState, setAuthState] = useState();
  const [collapsed, setCollapsed] = useState(false);
    const {Component} = props;
    const navigate = useNavigate()
    //const store = createStore(store);
    useEffect(()=>{
        let auth = sessionStorage.getItem('isAuthenticated');
        console.log(auth);
        if (!auth) {
            navigate('/login')
        }
    })
    
    return (
     
      <SessionContext.Provider value={{ authState, setAuthState }}>
   
        <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={"15vw"}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            backgroundColor:'#E6FAFF'
          }}
        >
          <Sidebar></Sidebar>
        </Sider>

          <Content>
            <Component />
            </Content> 
         
        </Layout>
        </SessionContext.Provider>
        
    )
  }
  
  export default ProtectedRoute;
  