import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate} from "react-router-dom";
import { post,get } from '../../context/rest';
import {store, useGlobalState} from 'state-pool';
import Lottie from 'lottie-react';
import animationData from './84726-business-meeting-animation.json'
import './auth.css'
import button from '../home/h02YifhsUJ.json';
import BadCredentialsPopup from "./supportingComponents/BadCredentialsPopup";
import { AuthContext} from '../../data/utils/AuthContext';
import InstructionPopup from '../userDashboard/supportingComponents/InstructionsPopup';
// import { useDispatch } from 'react-redux';
// import { login } from '../../data/utils/authActions';
import Header from "../header/Header";

const Login = () => {
  const { login } = useContext(AuthContext);


  const [isInsPopupOpen, setInsPopupOpen] = useState();
  const handleInsClosePopup = () => {
    setInsPopupOpen(false);
   
    navigate("/userdashboard");
  };


  const [showPopup, setShowPopup] = useState(false);
  const closePopup = () => {
    navigate('/login');
    setShowPopup(false);
  };
  // const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const[name,setName] = useState('damini')
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const  handleSubmit = async (event) =>{
    event.preventDefault();
    var data = JSON.stringify(formData);
    const res = await post("auth/login", data, false);
    sessionStorage.setItem("token", res.accessToken);
    sessionStorage.setItem("isAuthenticated", true);
    delete res.accessToken;
  
   if(res.unauthorized){
    setShowPopup(true);
   }
   else{
    sessionStorage.setItem("firmId",res.firm.id);
    sessionStorage.setItem("userName",res.name);
    sessionStorage.setItem("userId",formData.userName);
    if (res.roles.includes("ADMIN")) {
      sessionStorage.setItem('isAdmin',true)
    }
    else{
      sessionStorage.setItem('isAdmin',false)
    }
    setInsPopupOpen(true);
   }
 
   }
  
  return (
    <div className="outer">
   <Header />
   {/* <div style={{backgroundColor:"#138ead",padding:"1rem",textAlign:"center",fontSize:"24px",fontWeight:"600",color:"whitesmoke"}}>
    <p>Registrations will be available shortly! Stay Tuned!</p>
   </div> */}
          <div className="wrapper">
      <div className="index-overlap-group2">
        <Lottie animationData={animationData}/>
            {/* <p className="index-text-wrapper">Any appropriate illustration or image can go here</p> */}
          </div>
         
          <div className="index-overlap-group3">
          
            <div className="right-cntr">
            {/* <h2 className="index-h-1">EaseOpt Minutes</h2> */}
          {showPopup && 
               <div className="err-msg-login">
                <h3>Invalid Username or password!</h3>
               </div>
          }
            <div className="input-container">
              <label htmlFor="">Username</label>
              <input type='text' className="form-input input" name="userName" id="username" onChange={(event) => {handleInputChange(event)}} />
             
            </div>
            <div className="input-container">
              <label htmlFor="">Password</label>
              <input type='password' className="form-input input" name="password" onChange={(event) => {handleInputChange(event)}}/>
            </div>
            <div className="index-large-button">
              <div className="index-overlap-group">
                <div className="btn" onClick={handleSubmit}>Login</div>
              </div>
            </div>
            <div className="link">
            <Link className="index-p" to="/register">Not a user? Register Here!</Link>
            <Link className="index-p" to="/forgotPassword" style={{display:"block",marginTop:"1rem",fontWeight:"600"}}>Forgot Password?</Link>
            </div>
           
            </div>
          </div>
    </div>
    {isInsPopupOpen && (
                    <div className="popup-overlay">
                    <InstructionPopup onClose={handleInsClosePopup} onSave={handleInsClosePopup}/>
                    </div>
                )}
    </div>
    
  )
}

export default Login