import React from 'react'

function SessionExpiredPopup({onClose}) {
    return (
        <div className="popup session-popup" style={{height:"15%"}}>
          <div className="popup-content session-popup-content">
           
          <div className="popup-header">
            <p className='session-popup-header'>Login Failed!</p>
            <button onClick={onClose}>X</button>
        </div>
            <p>Invalid username or password!</p>
           
          </div>
        </div>
      );
}

export default SessionExpiredPopup