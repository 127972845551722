import React , { useEffect , useState } from 'react'
import {DIRECTOR_WITHOUT_DESIGNATION_WIDGET,FIRM_ADDRESS_WIDGET,LIST_OF_DIRECTORS_WIDGET,LIST_OF_SHARE_HOLDERS_WIDGET,LIST_OF_SHARE_HOLDER_AND_DIRECTORS_WIDGET,LIST_OF_SUB_TEMPLATES_WIDGET,SHARE_HOLDER_NAME_WIDGET,DIRECTOR_NAME_WIDGET,DIRECTOR_OR_SHARE_HOLDER_NAME_WIDGET,SECRETARIAL_AUDITORS_WIDGET,STATUTORY_AUDITORS_WIDGET,COST_AUDITORS_WIDGET,TRIGGER_WIDGET,DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET,SHARE_HOLDER_TABLE_OF_ATTENDANCE_WIDGET,SHARE_HOLDER_AND_DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET } from './widgets';
import {MyDatePicker,FINANCIAL_YEAR_WIDGET,TYPE_OF_BUSINESS_WIDGET,INTEGER_WITH_SUFFIX_WIDGET,AUDITORS_WIDGET,VOTE_BY_TYPE_WIDGET ,INTEGER_WIDGET, STRING_WIDGET, BOOLEAN_WIDGET , DATETIME_WIDGET , DATE_WIDGET , DECIMAL_WIDGET , DATE_WITH_DAY_WIDGET , TIME_WIDGET,FIRM_NAME_WIDGET,RELATED_PARTY_TRANSACTION_TABLE} from './widgets';
import Popup from './popup';
import { Card } from 'antd';
function LoadParameters({index,label, onFormUpdate ,type, value ="" , company , subTemplatesList, isHidden,financialYear}) {
   function handleIntChange(val) {
    console.log("parameter",val);
    onFormUpdate(index, val);
   }
   let componentToRender;
   

   switch (type) {
    case "INTEGER":
      if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
      
       <INTEGER_WIDGET  company={company} setData={handleIntChange} />
       </div>
       
     }
     break;
   case "INTEGER_WITH_SUFFIX":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label> 
       <INTEGER_WITH_SUFFIX_WIDGET  company={company} setData={handleIntChange} />
       </div>
       
     }
     break;
   case "STRING":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label> 
       <STRING_WIDGET   company={company} setData={handleIntChange} />
       </div>
      
     }
     break;
   case "DECIMAL":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <DECIMAL_WIDGET company={company} setData={handleIntChange} />
       </div>
     }
     break;
   case "BOOLEAN":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <INTEGER_WIDGET  company={company} setData={handleIntChange} />
       </div>
      
     }
     break;
   case "DATE":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       {/* <DATE_WIDGET company={company} setData={handleIntChange} /> */}
       <MyDatePicker company={company} setData={handleIntChange} fy={financialYear} />
       </div>
       
     }
     break;
   case "DATE_WITH_DAY":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     {/* <DATE_WITH_DAY_WIDGET company={company} setData={handleIntChange} />  */}
     <MyDatePicker company={company} setData={handleIntChange} fy={financialYear} />
     </div>
       
     }
     break;
   case "DATETIME":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <DATETIME_WIDGET company={company} setData={handleIntChange}/>
     </div>
      
     }
     break;
   case "TIME":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <TIME_WIDGET company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "FIRM_NAME":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <FIRM_NAME_WIDGET company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "FIRM_ADDRESS":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <FIRM_ADDRESS_WIDGET company={company} setData={handleIntChange}/>
     </div>
      
     }
     break;
   case "LIST_OF_DIRECTORS":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <LIST_OF_DIRECTORS_WIDGET  company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "DIRECTOR_WITHOUT_DESIGNATION":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <DIRECTOR_WITHOUT_DESIGNATION_WIDGET  company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "LIST_OF_SHARE_HOLDERS":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <LIST_OF_SHARE_HOLDERS_WIDGET company={company} setData={handleIntChange}/>
     </div>
      
     }
     break;
   case "LIST_OF_SHARE_HOLDER_AND_DIRECTORS":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <LIST_OF_SHARE_HOLDER_AND_DIRECTORS_WIDGET company={company} setData={handleIntChange}/>
     </div>
      
     }
     break;
   case "SHARE_HOLDER_NAME":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <SHARE_HOLDER_NAME_WIDGET company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "DIRECTOR_NAME":
     if (isHidden == 'N') {  componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
     <DIRECTOR_NAME_WIDGET company={company} setData={handleIntChange}/>
     </div>
       
     }
     break;
   case "DIRECTOR_OR_SHARE_HOLDER_NAME":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
       <DIRECTOR_OR_SHARE_HOLDER_NAME_WIDGET company={company} setData={handleIntChange}/>
       </div>
       
     }
     break;
   case "DAY":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label> 
       <DATE_WITH_DAY_WIDGET company={company} setData={handleIntChange}/>
       </div>
       
     }
     break;
   case "SECRETARIAL_AUDITORS":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>  
       <AUDITORS_WIDGET company={company} setData={handleIntChange} type="SECRETARIAL"/>
       </div>
       
     }
     break;
   case "STATUTORY_AUDITORS":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <AUDITORS_WIDGET company={company} setData={handleIntChange} type="STATUTORY"/>
       </div>
       
     }
     break;
   case "COST_AUDITORS":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <AUDITORS_WIDGET company={company} setData={handleIntChange} type="COST"/>
       </div>
       
     }
     break;
   case "AUDITORS":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <AUDITORS_WIDGET company={company} setData={handleIntChange} />
       </div>
      
     }
     break;
   case "TRIGGER":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> 
       <label htmlFor={index}>{label}</label> 
       <TRIGGER_WIDGET company={company} setData={handleIntChange} />
       </div>
       
     }
     break;
   case "DIRECTOR_TABLE_OF_ATTENDANCE":
      
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label> 
       <DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET company={company} setData={handleIntChange}/>
       </div>
     break;
   case "SHARE_HOLDER_TABLE_OF_ATTENDANCE":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
       <SHARE_HOLDER_TABLE_OF_ATTENDANCE_WIDGET company={company} setData={handleIntChange}/>
        </div>
       
     }
     break;
   case "SHARE_HOLDER_AND_DIRECTOR_TABLE_OF_ATTENDANCE":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label> 
       <SHARE_HOLDER_AND_DIRECTOR_TABLE_OF_ATTENDANCE_WIDGET company={company} setData={handleIntChange}/>
       </div>
       
     }
     break;
   case "VOTE_BY_TYPE":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
       <VOTE_BY_TYPE_WIDGET company={company} setData={handleIntChange} key={type.toString()}/>
       </div>
      
     }
     break;
   case "TYPE_OF_BUSINESS":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container"> <label htmlFor={index}>{label}</label>
       <TYPE_OF_BUSINESS_WIDGET company={company} setData={handleIntChange}/>
       </div>
       
     }
     break;
   case "FINANCIAL_YEAR":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container">
       <label htmlFor={index}>{label}</label>
       <FINANCIAL_YEAR_WIDGET setData={handleIntChange} />
      
       </div>
       
     }
     break;
   case "RELATED_PARTY_TRANSACTION_TABLE":
     if (isHidden == 'N') { 
        componentToRender = <div className="input-container">
         <label htmlFor={index}>{label}</label>
         <RELATED_PARTY_TRANSACTION_TABLE company={company} setData={handleIntChange} key={type.toString()}/>
       </div>
     }
     break;
 
   default:
     break;
 }
  return (
    
        <div>{componentToRender}</div>       
   
 
  )
}

export default LoadParameters