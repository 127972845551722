import React, { useRef, useState } from 'react'
import { Player } from 'video-react';
import './/userGuideVideo.css';
import Lottie from 'lottie-react';
import button from '../home/h02YifhsUJ.json';
import 'video-react/dist/video-react.css'

function UserGuideVideo() {
    const [isOpen,setIsOpen] = useState(false);
    const handleClick = event => {
   
        if (isOpen) {
          setIsOpen(false);
        }
        else{
          setIsOpen(true);
        }
      };
  return (
    <div>
         {/* <div className="home user">
       
      <div class="header-new header-home"> 
       <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3>EaseOpt</h3>
        </div>
    
    <div class="header-right">
              <a class="header-link" href='/home'>Home</a>
              <a class="header-link" href='/home'>About Us</a>
              <a class="header-link" href='/userGuide'>User Guide</a>
              <a class="header-link" href='/home'>Why EaseOpt</a>
              <a class="header-link" href='/home'>EaseOpt Solutions</a> 
              <a class="header-link" href='/query'>Query</a>
              <a class="header-link" href='/login' className='test' id='login'>Login</a>
    </div>
  </div>
   <div className="user-text-content">
              <p className="home-header">
                User Guide
              </p>
            </div>
      </div> */}
      <header className='home nav-main'>
        <div className="navbar">
        <div className="logo">
        <div className="nav-logo">
          {/* <div className="img-container">
          <Lottie animationData={button} />
          </div> */}
          <h3>EaseOpt</h3>
        </div>
        </div>
        <ul className='links'>
          <li><a href='/home'>Home</a></li>
          <li><a href='/home'>About Us</a></li>
          <li><a href='/userGuide'>User Guide</a></li>
          <li><a class="header-link" href='/userguidevideo'>Video Guide</a></li>
          <li><a href='/home'>Why EaseOpt</a></li>
          <li><a href='/home'>EaseOpt Solutions</a></li>
          <li><a href='/query'>Query</a></li>
        </ul>
        <a href="/login" className='action-btn'>Login</a>
        <div className="toggle-btn">
             <i class="fa-solid fa-bars" onClick={handleClick}></i>
        </div>
        </div>
       <div className={`dropdown-menu${isOpen?' open':''}`}>
       <li><a href='/home'>Home</a></li>
          <li><a href='/home'>About Us</a></li>
          <li><a href='/userGuide'>User Guide</a></li>
          <li><a href='/home'>Why EaseOpt</a></li>
          <li><a href='/home'>EaseOpt Solutions</a></li>
          <li><a href='/query'>Query</a></li>
          <li><a href="/login" className='action-btn-dp'>Login</a></li>
       </div>
      </header>
        <section id="User-Guide-video">
        <div className='video-container'>
           <div className='demo-text'>
              <h2>Demo Video</h2>
              <p>A step by step guide to learn more about the software.</p>
            </div>
            <div className='main-video-container'>
            <Player style={{boxShadow: "6px 6px 4px rgba(0, 0, 0, 0.25)",borderRadius:"5px"}}>
              <source src={require('.//assets//EaseOpt Demo.mp4')} />
            </Player>
            </div>
        </div>
      </section>
      <section id="footer">
        <div className="footer-logo">
        <div className="nav-logo">
          <div className="img-container">
          <Lottie animationData={button} />
          </div>
          <h3 style={{color:"#026882",marginTop:"10px"}}>EaseOpt</h3>
        </div>
        </div>
        <div className="footer-nav-links">
        <ul>
            <li><a href='/home'>Home</a></li>
            <li><a href='#About'>About Us</a></li>
            <li><a href='/userGuide'>User Guide</a></li>
            <li><a href='#Solution'>Why EaseOpt</a></li>
            <li><a href='#EaseOpt'>EaseOpt Solutions</a></li>  
            <li><a href='/query'>Query</a></li>  
          </ul>
        </div> 
            <div className='footer-details'>
                <h3>EaseOpt Solutions Private Limited</h3>
                <p>CIN: U47413MH2023PTC398235</p>
                <p>Email id: contact@easeoptsolutions.in</p>
                <p>Registered office: Flat No. 101, Green Woods Appt.,<br /> Plot No. 733/2B/2/9 B/h Idea Showroom, Samartha Nagar,<br /> Nashik - 422005 Maharashtra, India.</p>
            </div>
      </section>
    </div>
  )
}

export default UserGuideVideo