import React from 'react'
import {
  Select
} from "antd";
function Company({options , onFormUpdate, val}) {
    var index = 0
    if (val != undefined) {
      onFormUpdate(index,val);
    }
    const handleInputChange = (value) => {
       
         // setValue(event.target.value)
         console.log("company chnaged to: ",value);
          onFormUpdate(index,value);
        };
  return (
    <div className='input-container'>
        <label htmlFor="">Name of Company</label>
        <Select  id={index} onChange={(value) => handleInputChange(value)} required
    showSearch
    placeholder="Select Company Name"
    optionFilterProp="children"
    filterOption={(input, option) =>
       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    defaultValue={val}
  >
      {options.map((option, index) => (
        <option key={index} value={option.name}>
          {option.name}
        </option>
      ))}
    </Select>
        
    </div>
  )
}

export default Company