import React, { useContext, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Link } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import CompanyDetails from './components/company/CompanyDetails';
import CompanyList from './components/company/CompanyList';
import TemplateList from './components/template/TemplateList'
import TemplateDetails from './components/template/TemplateDetails';
import UserGuide from './components/userGuide/UserGuide';
import Home from './components/home/Home';
import Login from './components/auth/Login'
import Register from './components/auth/Register'
import Logout from './components/auth/Logout'
import './App.css';
import TemplateEdit from './components/template/TemplateEdit';
import DocumentDetails from './components/document/DocumentDetails';

import UserDashboard from './components/userDashboard/UserDashboard';
import Query from './components/query/Query';


import CompanyEdit from './components/company/CompanyEdit';
import { AuthContext} from './data/utils/AuthContext';
import { Provider } from 'react-redux';
import Subscription from './components/subscription/Subscription';
import UpdatePassword from './components/emailVerification/UpdatePassword';
import Terms from './components/home/Terms'
import ForgetPassword from './components/emailVerification/forgetPassword';
import PaymentStatus from './components/subscription/PaymentStatus';
import UserGuideVideo from './components/userGuide/userGuideVideo';

function App() {
  const [isAdmin,setIsAdmin] = useState(false)
  return (
  
    <BrowserRouter>
     {/* <AuthContext.Consumer>
     {(context) => ( */}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/userguide" element={<UserGuide />}></Route>
        <Route path="/userguidevideo" element={<UserGuideVideo />}></Route>
        <Route path="/query" element={<Query />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/updatepassword" element={<UpdatePassword />}></Route>
        <Route path="/forgotPassword" element={<ForgetPassword />}></Route>
        <Route path="/paymentStatus" element={<PaymentStatus />}></Route>
       
        <Route path="/companies" element={<ProtectedRoute Component={CompanyList} />}></Route>
        <Route path="/company/new" element={<ProtectedRoute Component={CompanyDetails} />}></Route>
         <Route path="/company/:id" element={<ProtectedRoute Component={CompanyEdit} />}></Route>
        <Route path="/templates" element={<ProtectedRoute Component={TemplateList} />}></Route>
        <Route path="/template/new" element={<ProtectedRoute Component={TemplateDetails} />}></Route>
        <Route path="/template/new" element={<ProtectedRoute Component={TemplateDetails}  />}></Route>
        <Route path="/template/:id" element={<ProtectedRoute Component={TemplateEdit} />}></Route>
        <Route path="/document/new" element={<ProtectedRoute Component={DocumentDetails}/>}></Route>
        <Route path="/document/new/:name" element={<ProtectedRoute Component={DocumentDetails}/>}></Route>
        <Route path="/userdashboard" element={<ProtectedRoute Component={UserDashboard}  />}></Route>
        <Route path="/subscription" element={<ProtectedRoute Component={Subscription}  />}></Route>
        


      </Routes>
      {/* )}
      </AuthContext.Consumer> */}
    </BrowserRouter>
  );
}

export default App;
