import React, { useState } from "react";
import User from "./supportingComponents/User";
import { post } from "../../context/rest";
import './auth.css'
import button from '../home/h02YifhsUJ.json';
import Lottie from 'lottie-react';
import Header from "../header/Header";
import { Link, useNavigate} from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { UploadOutlined, ExclamationCircleFilled , CloseCircleOutlined,CheckCircleOutlined } from "@ant-design/icons";
import { Modal } from 'antd';

const Register = () => {
  const { confirm } = Modal;
  const [subComponents, setSubComponents] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const closePopup = () => {
    navigate('/login');
    setShowPopup(false);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    setIsSubmitEnabled(event.target.checked);
    document.getElementById('registerBtn').classList.toggle('disabled');
  };

  const toggleDisclaimer = () => {
    setIsDisclaimerVisible(!isDisclaimerVisible);
  };


  const navigate = useNavigate();
  const addSubComponent = () => {
    setSubComponents([...subComponents, {}]);
  };

  const removeSubComponent = (index) => {
    const updatedComponents = [...subComponents];
    updatedComponents.splice(index, 1);
    setSubComponents(updatedComponents);
  };

  const handleValueChange = (index, updatedValues) => {
    const updatedComponents = [...subComponents];
    updatedComponents[index] = updatedValues;
    setSubComponents(updatedComponents);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Collect data of all subcomponents in one array
    const allData = subComponents.map((component) => ({
      name: component.name,
      email: component.email,
     // password: component.password,
      contactNo: component.contact,
      userRoles: [{role:'USER',isActive:"Y"}],
    }));
    console.log(allData); // You can do whatever you want with the data here
    var companyName = document.getElementById('companyName').value;
    var formData = {
      firm:{name:companyName},
      users:allData
    }
    const multilineContent = (
      <div>
        <h4>Possible Reasons:</h4>
        <ol>
          <li>Firm name is already registered.</li>
          <li>User details are already used.</li>
        </ol>
      </div>
    )
    var response = await post("subscription/add", JSON.stringify(formData), false);
    if (response.isRegistered != undefined && response.isRegistered== false) {
      console.log("Something went wrong");
      return confirm({
        title: "Something went wrong!",
        content:multilineContent, 
        icon: <CloseCircleOutlined />,
        onOk(){
          navigate(1);
        }
      })
    }
    else{
      return confirm({
        title: "Registration Successful!",
        content:"Authentication email has been sent successfully!",
        icon: <CheckCircleOutlined />,
        onOk(){
          navigate(-1);
        }
      })
    }
  };

  
  return (
    <div className="reg-outer">
      <Header />
   <div className="list-container register-container">
      
      <form className="div-container">
      <h2 className="r-heading">Register User</h2>
      <div className="form-container">
      <div className="top-row">
        <div className="input-container companyName">
            <label htmlFor="">Associated Entity name</label>
            <input type="text" id="companyName" className="form-input" required/>
        </div>
        {/* <div className="input-container">
            <label htmlFor="">Select Subscription Type</label>
            <select className="form-input" id="type" required>
              <option value=""></option>
              <option value="MOM">MOM Generator For Year</option>
              <option value="YEARLY">Yearly</option>
              </select>
        </div> */}
        </div>
       
        {/* <div className="subscription">
          <div className="input-container">
            <label htmlFor="">Start Date</label>
            <input type="date" name="" id="startDate" className="form-input" required/>
          </div>
          <div className="input-container">
            <label htmlFor="">Subscription Period</label>
            <input type="number" name="" id="period" className="form-input" required/>
          </div>
        </div> */}
       
       <div>
        <div className="form-header"><label>User</label>

        <button type="button" onClick={addSubComponent}>
          Add User
        </button>
        </div>
        <div className='r-inner-form'>
       
        {subComponents.map((_, index) => (
          <User
            key={index}
            onValueChange={(values) => handleValueChange(index, values)}
            onRemove={() => removeSubComponent(index)}
          />
        ))}
       
        </div>
       </div>
       <div className="disclaimer">
       <div>
          <label>
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            I agree to the terms and conditions.
          </label>
        </div>
        <div>
          <button type="button" onClick={toggleDisclaimer} className="dis-btn">
            {isDisclaimerVisible ? 'Hide' : 'Show'}
          </button>
        </div>
       </div>
      
        {isDisclaimerVisible && (
          <div>
            <p className="disclaimer-text">
            Attention is derived to all those accessing the website. We at EaseOpt aim to provide templates and formats for Minutes preparation which are useful to various professionals’ based on updates and amendments made in the prevailing law, rules and regulations such as Companies Act, 2013, SEBI Regulations, etc. But while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purposes. Any reliance you place on such information is therefore strictly at your own risk. In no event EaseOpt will be liable for any loss or damage caused due to misinterpretation of or consequential loss arising out of or in connection with the use of this website.
            </p>
          </div>
        )}
        <div>
          {/* <button type="submit" disabled={!isSubmitEnabled}>
            Register
          </button> */}
           <div className="r-btn-container">
        <button id="registerBtn" type="submit" className="long-btn disabled" onClick={handleSubmit} disabled={!isSubmitEnabled}>Submit</button>
        </div>
        </div>
       
      </div>
        
        
      </form>
    </div>
    </div>
   
  );
};

export default Register;
